// BarChart.js
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const MonthlyStatsBarChart = ({ data }) => {
  const options = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Credits Utilized by Month",
    },
    xAxis: {
      categories: data?.map((item) => item?.month),
      title: {
        text: "Month",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Credits Utilized",
      },
    },
    series: [
      {
        name: "Credits Utilized",
        data: data?.map((item) => item?.creditsUtilized),
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default MonthlyStatsBarChart;
