import React, { memo } from "react";
import "./AnalyzeWorkSpace.scss";
import { useState } from "react";
import {
  addViewColumns,
  downloadWorkspaceRecord,
  getAnalyzeSpecification,
  getPowerBi,
  getPowerBiDashBoardForWorkSpace,
  getSortColumns,
  getTaxonomy,
  getViewColumns,
  getWorkSpaceById,
  getWorkspaceSearchTermForSearchWithCancelReq,
  onSearchWorkspace,
  salesActivityTrack,
} from "../../../../Services/service";
import { useEffect } from "react";
import {
  ApprovSuccess_viewColumns,
  DownLoad_Excel_Notify,
  DownLoad_Excel_SuccessFull,
  FetchData,
  RecordLimit,
  RemovingDuplicateRecord,
  Retrieving_Workspaces,
  hsCodeSelectLimit,
} from "../../../../utils/LoadingMsg";
import {
  appendZeroAtStart,
  createOperator,
  divideDateRange,
  getChildValue,
  getCookie,
  getMonthName,
  sanitizeHsCodeDigitBoundaries,
  translateButtonClick,
} from "../../../../utils/CommanFunction";
import ErrorData from "../../../../utils/ErrorHandling";
import moment from "moment";
import {
  ADD_USER_SEARCH_IN_SEARCH_OPTIONS,
  ADVANCESEARCHFIELDNAME,
  BUTTON_NAME,
  DATEFORMAT,
  FILTER_OPTIONS,
  QUERYTYPE,
  SEARCHTYPE,
  SHIPMENT_RESULT_TYPE_RECORDS,
  SORTINGTYPE,
  MirrorDataPayload,
  ADD_USER_SEARCH_IN_SEARCH_OPTIONS_VALIDATE_FIELD,
  SELECT_OPTIONS_VALIDATE_FIELD,
} from "../../../ExploreCountryShipmentNewUI/Explore.enum";
import { useLocation } from "react-router";
import Loading from "../../../../Component/SuccessLoader/success";
import ErrorLoading from "../../../../Component/ErrorLoader/error";
import SearchComponent from "../../../../Component/SearchComponentNewUI/SearchComponent";
import _ from "lodash";
import {
  IsMultiSelectedField,
  IsMultiSelectedFieldForAdvanceSearch,
} from "../../../../utils/commanConditions";
import AdvanceSearchComponent from "../../../../Component/AdvanceSearchComponentNewUI/AdvanceSearchComponent";
import Summary from "../../../../Component/SummaryBarForExploreShipmentNewUI/Summary";

import throwError from "../../../../utils/throwError";
import {
  convertDataIntoLeftAndRightValue,
  packDTParams,
} from "../../../../utils/CreateRecordAndFilterPayload";
import {
  INDIA_EXPORT_COLUMN_NAME,
  INDIA_IMPORT_COLUMN_NAME,
} from "../../../../IndiaTradeRecords/records";
import {
  KAZAKHSTAN_EXPORT_RECORDS,
  KAZAKHSTAN_IMPORT_RECORDS,
} from "../../../../kazakhstanTradeRecords/KazakRecords";
import { DefaultWorkSpaceTableHeader } from "../../Workspace.enum";
import ApprovedSuccess from "../../../../Component/ApprovedSuccessModal/ApprovedSuccess";
import ShipmentNewUI from "../Shipement/ShipmentNewUI";
import WorkSpaceButton from "../../Component/WorkSpaceButton/WorkSpaceButton";
import PowerBIReport from "../../../../Component/PowerBiDashBoard/PowerBiDashBoard";
import PercentageLoader from "../../../../Component/PercentageLoader/LoaderWithPercentage";
import ModalComponent from "../../../../Component/Modal/modal";
import { logger } from "../../../../Logger/Logger";
const urlHost = process.env.REACT_APP_BASE_LIVE;
let advanceSearchDefaultObj = {};
const AnalyzeWorkSpace = memo((props) => {
  const search = useLocation().search;
  const workspaceId = new URLSearchParams(search)?.get("workspaceId");
  const currentTradeType = new URLSearchParams(search)?.get("tradeType");
  const currentCountryCode = new URLSearchParams(search)?.get("countryCode");
  const isBlFlag =
    new URLSearchParams(search)?.get("blFlag")?.toLowerCase() === "true";
  const currentCountry = new URLSearchParams(search)?.get("country");
  let mirrorData = false;
  if (currentCountry === "MIRROR_TABLE") {
    mirrorData = true;
  }
  const _startDate = new URLSearchParams(search).get("startDate");
  const [currentWorkspaceStartDate, setCurrentWorkspaceStartDate] =
    useState(_startDate);
  const _endDate = new URLSearchParams(search).get("endDate");

  const [currentWorkspaceEndDate, setCurrentWorkspaceEndDate] =
    useState(_endDate);
  const currentWorkspaceBucket =
    new URLSearchParams(search)?.get("workspaceBucket") || "";
  const currentWorkspaceName = new URLSearchParams(search)?.get(
    "workspaceName"
  );

  const [selectTrade, setSelectTrade] = useState({
    tradeType: currentTradeType === "IMPORT" ? "IMPORT" : "EXPORT",
    isBlFlag:
      new URLSearchParams(search)?.get("blFlag")?.toLowerCase() === "true",
    country: currentCountry,
    CountryOptions: [],
  });

  const [filteredTaxonomy, setFilteredTaxonomy] = useState({});
  const [analyzeSpecification, setAnalyzeSpecification] = useState([]);
  const [analyzeSpecificationData, setAnalyzeSpecificationData] = useState([]);

  const [errorMsg, setErrorMsg] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  //ApprovedSuccess modal
  const [approvSuccessModalData, setApprovSuccessModalData] = useState({});
  const [approvSuccessModal, setApprovSuccessModal] = useState(false);

  //search
  const [searchField, setSearchField] = useState({
    getSelectExploreField: "",
    getValuesForSelectDropdown: {},
    searchOption: {},
    operatorOptions: [],
    selectedOperator: {},
  });
  const [inputValues, setInputValues] = useState(null);

  const [dateRange, setDateRange] = useState({
    minDateForDatePicker: "",
    monthRange: 0,
    maxDateForEndMonth: "",
    endMonthDate: "",
    startMonthDate: "",
  });
  const [reactSelectLoading, setReactSelectLoading] = useState(false);
  const [getSearchOption, setSearchOptionForMain] = useState([]);
  const [getSelectedOptionForSearchTerm, setSelectedOptionForSearchTerm] =
    useState(null);
  const [isSearchValueEmpty, setIsSearchValueEmpty] = useState({
    searchTerm: false,
    advancedSearch: false,
  });
  //advanceSearch
  const [advanceSearchFieldOptions, setAdvanceSearchFieldOptions] = useState(
    []
  );
  const [advanceSearchComponent, setAdvanceSearchComponent] = useState([]);
  const [reactSelectAdvanceLoading, setReactSelectAdvanceLoading] =
    useState(false);
  const [defaultOptionForSearchState, setDefaultOptionForAdvancedSearch] =
    useState([]);
  const [emptyErrorAdvanceSearch, setEmptyErrorAdvanceSearch] = useState([]);
  const [currentAdvanceSearchCmpIndex, setCurrentAdvanceSearchCmpIndex] =
    useState(0);

  // payload
  const [getSearchValueData, setSearchValueData] = useState({});

  //filter
  const [isFilterLoader, setIsFilterLoader] = useState(false);
  const [appliedFilterData, setAppliedFilterData] = useState({});
  const [getAllSplitFilters, setGetAllSplitFilters] = useState();
  const [allFilterValuesWithCountsArr, setAllFilterValuesWithCountsArr] =
    useState({});
  const [getAllFilterValueArray, setAllFilterValueArray] = useState([]);
  const [initialAllFilterValues, setInitialAllFilterValues] = useState({});
  const [keyValuePairForTreeview, setPairForTreeView] = useState({});
  const [rangeValue, setRangeValue] = useState({});
  const [getMaxMinRange, setMaxMinRange] = useState({});
  const [storeFilteredValues, setStoreFilterValues] = useState({});
  const [CheckedData, setCheckedData] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const [selectedQuantityOrPriceValue, setSelectedQuantityOrPrice] = useState(
    {}
  );
  const [apply, setApply] = useState({
    HS_CODE: false,
    FILTER_COUNTRY: false,
    FILTER_PORT: false,
    QUANTITY: false,
    PRICE: false,
    MONTH: false,
  });
  const [isFiltersShown, setIsFiltersShown] = useState(true);
  const [headerTitle, setHeaderTitle] = useState("");
  const [getFilterValues, setFilterValue] = useState([]);
  const [getAllFilterValues, setGetAllFilterValues] = useState([]);
  //view Columns
  const [handleOpenFilterModal, setHandleOpenFilterModal] = useState(false);
  //remove duplicate
  const [isRemoveDuplicateButtonModal, setIsRemoveDuplicateButtonModal] =
    useState(false);
  const [isRemoveDuplicateButton, setIsRemoveDuplicateButton] = useState(false);
  const [ChangeRemoveDuplicateButton, setIsChangeRemoveDuplicateButton] =
    useState(false);
  //table
  const [accountTableHeader, setAccountTableHeader] = useState([]);
  const [dataTableRowValues, setDataTableRowValues] = useState([]);
  const [allHeader, setAllHeader] = useState([]);
  const [viewHeader, setViewHeader] = useState({});
  const [dataTableHeader, setDataTableHeader] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [draw, setDraw] = useState(1);
  const [descendingAndAscending, setDescendingAndAscending] = useState({});
  const [sortedColumnList, setSortedColumnList] = useState();

  var onSearchClickFlag = false;
  var removeButtonState = {};
  const [isDisplayRec, setDisplayRec] = useState(false);
  const [intialCall, setIntialCall] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [isVisibleData, setVisibleData] = useState(false);
  // const [emptyWorkspaceData, setEmptyWorkspaceData] = useState(false);
  const [isCalledViewColumnApi, setIsCalledViewColumnApi] = useState(false);
  // const [selectedTab, setSelectedTab] = useState(1);
  //Current selected workspace
  const [currentSelectedWorkSpace, setCurrentSelectedWorkSpace] = useState({});
  //setAllRecord
  const [allRecord, setAllRecord] = useState([]);
  //graph
  // const [
  //   currentAnalyticsCorrelationTradeFactors,
  //   setCurrentAnalyticsCorrelationTradeFactors,
  // ] = useState(null);
  const [monthYearArray, setMonthYearArray] = useState([]);
  // const [currentFilterCountForGraph, setCurrentFilterCountForGraph] = useState({
  //   currentFilteredImporterCount: 0,
  //   currentFilteredExporterCount: 0,
  //   currentFilteredHsCodeCount: 0,
  //   currentFilteredPortCount: 0,
  //   currentFilteredCountryCount: 0,
  // });
  // const [currentFilterCountForGraph2, setCurrentFilterCountForGraph2] =
  //   useState({
  //     currentFilteredPortCount: 0,
  //     currentFilteredCountryCount: 0,
  //   });
  // const [currentFilterCountForGraph3, setCurrentFilterCountForGraph3] =
  //   useState({
  //     currentFilteredImporterCount: 0,
  //     currentFilteredExporterCount: 0,
  //     currentFilteredHsCodeCount: 0,
  //   });
  const catchBlock = (err) => {
    const errorForLogger = `Analyze WorkSpace:- ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setIsFilterLoader(false);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? ErrorData?.SESSION_TIMEOUT
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };
  const getTaxonomyAndSpecificationData = async () => {
    let taxonomyPayload = {
      currentTradeType: currentTradeType,
      currentCountry: currentCountry,
    };
    const payload = { page_no: 1, account_id: props?.CLIENT_ACCOUNT_ID };

    const [taxonomy, analyzeSpecification, currentWorkspace] =
      await Promise.all([
        getTaxonomy(taxonomyPayload),
        getAnalyzeSpecification(
          props.CLIENT_USER_ID,
          workspaceId,
          currentTradeType,
          currentCountryCode
        ),
        getWorkSpaceById(props?.CLIENT_USER_ID, workspaceId, payload),
      ]);
    return {
      taxonomy: taxonomy,
      analyzeSpecification: analyzeSpecification,
      currentWorkspace: currentWorkspace,
    };
  };
  const [totalWorkspaceRecords, setTotalWorkspaceRecords] = useState(0);
  //PowrBiDashboard
  const [powerBiDashBoard, setPowerBiDashBoard] = useState({});
  const [powerBiModelOpen, setPowerBiModelOpen] = useState(false);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [isRecordBtnChecked, setIsRecordBtnChecked] = useState(true);
  const [searchPayloadForPowerBi, setSearchPayloadForPowerBi] = useState({});
  const [progress, setProgress] = useState(0);

  const getPowerBiDashBoardDetails = (searchPayload) => {
    // setLoading(true);
    // setDashboardLoading(true);
    setDashboardLoading(true);
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress + 5 <= 98 ? prevProgress + 5 : 98
      );
    }, 1000);
    getPowerBiDashBoardForWorkSpace(searchPayload)
      .then((e) => {
        if (e?.status === 200) {
          let obj = e?.data;
          if (e?.data?.accessToken && e?.data?.datasetsId) {
            const payload = {
              datasets: [
                {
                  id: e?.data?.datasetsId,
                },
              ],

              reports: [
                {
                  allowEdit: false,
                  id: e?.data?.reportId,
                },
              ],
            };
            getPowerBi(payload, e?.data?.accessToken)
              .then((res) => {
                if (res.status === 200) {
                  setLoading(false);
                  setDashboardLoading(false);
                  obj.token = res?.data?.token;
                  setPowerBiDashBoard(obj);
                  setIsRecordBtnChecked(!isRecordBtnChecked);
                  setProgress(100);
                  setTimeout(() => {
                    clearInterval(interval);
                    setProgress(0);
                  }, 2000);
                  setDashboardLoading(false);
                } else {
                  throw throwError(res);
                }
              })
              .catch((err) => {
                setLoading(false);
                setDashboardLoading(false);
                catchBlock(err);
              });
          } else {
            setLoading(false);
            setDashboardLoading(false);
          }
        } else {
          throw throwError(e);
        }
      })
      .catch((err) => {
        setDashboardLoading(false);
        catchBlock(err);
      });
    const activityPayload = {
      country: searchPayload?.country,
      tradeType: searchPayload?.tradeType,
    };

    const salesTrackPayload = {
      activityType: "POWERBI_REPORT",
      activityPayload: activityPayload,
      user_id: getCookie("user_id"),
      account_id: getCookie("accountId"),
    };
    salesActivityTrack(salesTrackPayload);
  };
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setLoadingMsg(Retrieving_Workspaces);
      await getTaxonomyAndSpecificationData()
        .then((e) => {
          setLoading(true);
          if (e?.taxonomy?.data?.length > 0) {
            const currentCountryTaxonomy = e?.taxonomy?.data?.find(
              (e) => e?.bl_flag.toString() === isBlFlag.toString()
            );
            setFilteredTaxonomy(currentCountryTaxonomy);
            if (
              currentCountryTaxonomy?.fields &&
              currentCountryTaxonomy?.fields?.advance_search_semantic &&
              currentCountryTaxonomy?.fields?.advance_search_semantic?.length >
                0
            ) {
              let advanceSearchOption =
                currentCountryTaxonomy?.fields?.advance_search_semantic;
              setAdvanceSearchFieldOptions(advanceSearchOption);

              let operatorOptions = [];
              if (
                advanceSearchOption[0] &&
                advanceSearchOption[0]?.searchType
              ) {
                operatorOptions = createOperator(
                  advanceSearchOption[0]?.searchType
                );
              }

              advanceSearchDefaultObj = {
                relation: "AND",
                field: advanceSearchOption[0].field,
                operatorOptions: operatorOptions,
                operator:
                  operatorOptions &&
                  operatorOptions?.length > 0 &&
                  operatorOptions[0].type,
                value: "",
                query: advanceSearchOption[0].query,
              };
            }
          } else {
            throw { status: e?.taxonomy?.status };
          }
          if (
            e?.analyzeSpecification &&
            e?.analyzeSpecification?.data &&
            Object.keys(e?.analyzeSpecification?.data).length > 0
          ) {
            setAnalyzeSpecificationData(e?.analyzeSpecification);
            const specification = e?.analyzeSpecification?.data;
            let _searchField = {};
            _searchField = {
              getSelectExploreField:
                specification?.search_field_semantic[0]?.field,
              getValuesForSelectDropdown:
                specification?.search_field_semantic[0],
              searchOption: specification?.search_field_semantic,
            };
            if (
              specification?.search_field_semantic[0]?.searchType &&
              specification?.search_field_semantic[0]?.searchType?.length > 0
            ) {
              let operator =
                specification?.search_field_semantic[0]?.searchType;
              const operatorArr = operator.map((e) => {
                return { value: e?.type, title: e?.type };
              });
              _searchField.operatorOptions = operatorArr;
              _searchField.selectedOperator = operatorArr[0]?.value;
            } else {
              _searchField.operatorOptions = [];
              _searchField.selectedOperator = {};
            }
            setSearchField(_searchField);
            setTotalWorkspaceRecords(specification?.totalRecords);

            setAnalyzeSpecification(specification);
          } else {
            throw { status: e?.taxonomy?.status };
          }
          if (e?.currentWorkspace?.data?.results?.length > 0) {
            const currentWorkspaceId = e?.currentWorkspace?.data?.results?.find(
              (item) => item._id === workspaceId
            );
            setCurrentSelectedWorkSpace(currentWorkspaceId);
          } else {
            throw { status: e?.taxonomy?.status };
          }
          const obj = {
            startMonthDate: moment(currentWorkspaceStartDate).format(
              DATEFORMAT?.format
            ),
            endMonthDate: moment(currentWorkspaceEndDate).format(
              DATEFORMAT?.format
            ),
            maxDateForEndMonth: moment(currentWorkspaceEndDate).format(
              DATEFORMAT?.format
            ),
            monthRange: 1,
            minDateForDatePicker: moment(currentWorkspaceStartDate).format(
              DATEFORMAT?.format
            ),
          };
          setDateRange(obj);
        })
        .catch((err) => {
          catchBlock(err);
        });
    }
    fetchData();
  }, []);
  useEffect(() => {
    if (filteredTaxonomy && filteredTaxonomy?._id) {
      getAccountViewColumns(filteredTaxonomy?._id);
    }
  }, [filteredTaxonomy]);
  const getAccountViewColumns = (id) => {
    getViewColumns(id).then((res) => {
      if (res?.data) {
        if (
          res?.data?.data?.selected_columns &&
          res?.data?.data?.selected_columns?.length > 0
        ) {
          setAccountTableHeader(() => res?.data?.data?.selected_columns);
          setIsCalledViewColumnApi(true);
        } else {
          setAccountTableHeader([]);
          setIsCalledViewColumnApi(true);
        }
      }
    });
  };
  useEffect(() => {
    if (
      filteredTaxonomy &&
      Object.keys(filteredTaxonomy).length > 0 &&
      analyzeSpecificationData &&
      Object.keys(analyzeSpecificationData).length > 0
    ) {
      getSortColumns({ taxonomy: filteredTaxonomy })
        .then((res) => {
          if (res?.status === 500 || res?.status === 401) {
            throw throwError(res);
          } else {
            if (res?.data?.result?.length > 0) {
              if (res?.data?.result[0]?.sortMapping?.length > 0) {
                const sortMappingColumns = res?.data?.result[0];
                let sortedColumnList = [...sortMappingColumns?.sortMapping];
                let sortingObj = {};
                sortMappingColumns &&
                  sortMappingColumns?.sortMapping?.length > 0 &&
                  sortMappingColumns?.sortMapping?.forEach((e) => {
                    sortingObj[e?.column] = SORTINGTYPE?.default;
                  });
                const findClauseSearchMonth =
                  analyzeSpecificationData?.data?.explore_aggregation
                    ?.matchExpressions &&
                  analyzeSpecificationData?.data?.explore_aggregation
                    ?.matchExpressions?.length &&
                  analyzeSpecificationData?.data?.explore_aggregation?.matchExpressions.find(
                    (e) => e?.identifier === "SEARCH_MONTH_RANGE"
                  );
                if (
                  findClauseSearchMonth &&
                  Object.keys(findClauseSearchMonth)?.length > 0
                ) {
                  sortedColumnList.push({
                    column: findClauseSearchMonth?.fieldTerm,
                    defaultDataType: findClauseSearchMonth?.fieldTermTypeSuffix,
                    sortField: findClauseSearchMonth?.fieldTerm,
                  });
                  sortingObj[findClauseSearchMonth?.fieldTerm] =
                    SORTINGTYPE?.default;
                }
                setSortedColumnList(sortedColumnList);
                setDescendingAndAscending(sortingObj);
              }
            } else {
              setSortedColumnList([]);
            }
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    }
  }, [filteredTaxonomy, analyzeSpecificationData]);

  const getPayloadRelatedData = () => {
    const payload = {
      isBlFlag: isBlFlag,
      currentTradeType: currentTradeType,
      currentCountryCode: currentCountryCode,
      currentCountry: currentCountry,
      filteredTaxonomy: filteredTaxonomy,
      SHIPMENT_RESULT_TYPE_RECORDS: SHIPMENT_RESULT_TYPE_RECORDS,
      rowsPerPage: rowsPerPage,
      searchField: searchField,
      getSelectedOptionForSearchTerm: getSelectedOptionForSearchTerm,
      exploreShipmentSpecification: analyzeSpecificationData?.data,
      dateRange: dateRange,
      advanceSearchComponent: advanceSearchComponent,
      advanceSearchFieldOptions: advanceSearchFieldOptions,
      onSearchClickFlag: onSearchClickFlag,
      descendingAndAscending: descendingAndAscending,
      sortedColumnList: sortedColumnList,
      CheckedData: CheckedData,
      removeButtonState: removeButtonState,
      lastSelected: lastSelected,
      workSpaceIntialCall: intialCall,
      isWorkSpace: true,
      workspaceBucket: "",
      workspaceTotalRecords: totalWorkspaceRecords,
      currentSelectedWorkSpace: currentSelectedWorkSpace,
    };

    return payload;
  };

  useEffect(() => {
    if (
      analyzeSpecificationData &&
      Object.keys(analyzeSpecificationData).length > 0 &&
      searchField &&
      searchField.getSelectExploreField !== "" &&
      searchField.getValuesForSelectDropdown &&
      Object.keys(searchField?.getSelectExploreField).length > 0 &&
      dateRange?.startMonthDate !== "" &&
      dateRange?.endMonthDate !== "" &&
      !intialCall &&
      isCalledViewColumnApi
    ) {
      onSearchClick();
    }
  }, [analyzeSpecificationData, searchField, dateRange, isCalledViewColumnApi]);

  const createTableHeader = (header, country) => {
    let ValuesArr = [...DefaultWorkSpaceTableHeader];
    let arr = [];
    let sequencedFieldsArray = [];
    country !== "KAZAKHSTAN" &&
      analyzeSpecificationData?.data?.explore_fields?.forEach((tableField) => {
        sequencedFieldsArray.push(tableField);
      });
    for (const [key, value] of Object.entries(header)) {
      arr.push(key);
    }
    arr &&
      arr?.length > 0 &&
      arr?.forEach((tableField) => {
        if (!sequencedFieldsArray.includes(tableField)) {
          sequencedFieldsArray.push(tableField);
        }
      });

    sequencedFieldsArray &&
      sequencedFieldsArray.length &&
      sequencedFieldsArray.forEach((e) => {
        const obj = {
          key: e,
          title: header[e].split("_").join(" "),
        };
        ValuesArr.push(obj);
      });
    return { ValuesArr };
  };

  const accountWiseHeader = (ValuesArr) => {
    let headerArray = [...DefaultWorkSpaceTableHeader];
    let _viewHeader = {};
    ValuesArr?.forEach((e) => {
      if (e?.key !== "" && e?.key !== "add" && e?.key !== "checkbox") {
        if (accountTableHeader?.length > 0) {
          if (accountTableHeader?.includes(e?.key)) {
            headerArray.push(e);
            _viewHeader = { ..._viewHeader, [e.key]: true };
          } else {
            _viewHeader = { ..._viewHeader, [e.key]: false };
          }
        } else {
          headerArray.push(e);
          _viewHeader = { ..._viewHeader, [e.key]: true };
        }
      }
    });

    return { headerArray, _viewHeader };
  };
  const setTableHeaderAndData = (e, isSetAllHeader) => {
    const data = [...e?.data];
    data &&
      data.length > 0 &&
      data.map((ele) => {
        ele.isFavouriteShipment = false;
        if (currentCountry !== "INDIA") {
          ele.isFavourite = false;
        }
      });
    if (
      currentTradeType === "IMPORT" &&
      currentCountry === "INDIA" &&
      (isBlFlag === "false" || isBlFlag === false)
    ) {
      const { ValuesArr } = createTableHeader(INDIA_IMPORT_COLUMN_NAME);
      const { headerArray, _viewHeader } = accountWiseHeader(ValuesArr);
      setAllHeader(ValuesArr);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : ValuesArr
      );
    } else if (
      currentTradeType === "EXPORT" &&
      currentCountry === "INDIA" &&
      (isBlFlag === "false" || isBlFlag === false)
    ) {
      const { ValuesArr } = createTableHeader(INDIA_EXPORT_COLUMN_NAME);
      const { headerArray, _viewHeader } = accountWiseHeader(ValuesArr);
      setAllHeader(ValuesArr);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : ValuesArr
      );
    } else if (
      currentTradeType === "IMPORT" &&
      currentCountry === "KAZAKHSTAN" &&
      (isBlFlag === "false" || isBlFlag === false)
    ) {
      const { ValuesArr } = createTableHeader(
        KAZAKHSTAN_IMPORT_RECORDS,
        "KAZAKHSTAN"
      );
      const { headerArray, _viewHeader } = accountWiseHeader(ValuesArr);
      setAllHeader(ValuesArr);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : ValuesArr
      );
    } else if (
      currentTradeType === "EXPORT" &&
      currentCountry === "KAZAKHSTAN" &&
      (isBlFlag === "false" || isBlFlag === false)
    ) {
      const { ValuesArr } = createTableHeader(
        KAZAKHSTAN_EXPORT_RECORDS,
        "KAZAKHSTAN"
      );
      const { headerArray, _viewHeader } = accountWiseHeader(ValuesArr);
      setAllHeader(ValuesArr);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : ValuesArr
      );
    } else {
      let tableHeader = [...DefaultWorkSpaceTableHeader];

      const checkedArr = [];
      filteredTaxonomy?.fields?.explore?.length > 0 &&
        filteredTaxonomy?.fields?.explore?.forEach((e) => {
          tableHeader?.push({
            key: e,
            title: e.split("_").join(" "),
          });
          checkedArr.push(e);
        });

      if (data && data.length > 0) {
        for (const [key, value] of Object.entries(data[0])) {
          if (
            !key?.includes("RECORDS_TAG") &&
            key !== "_id" &&
            key !== "isFavourite" &&
            key !== "isFavouriteShipment"
          ) {
            if (!checkedArr.includes(key)) {
              const obj = {
                key: key,
                title: key.split("_").join(" "),
              };
              tableHeader.push(obj);
            }
          }
        }
      }
      const { headerArray, _viewHeader } = accountWiseHeader(tableHeader);
      setAllHeader(tableHeader);
      setViewHeader(_viewHeader);
      setDataTableHeader(
        accountTableHeader?.length > 0 ? headerArray : tableHeader
      );
    }
    // const startRecord = page * rowsPerPage;
    // const _data = data.slice(startRecord, rowsPerPage + startRecord);
    const _data = data;
    for (const item of _data) {
      item &&
        Object.keys(item).length > 0 &&
        Object.keys(item).forEach((property) => {
          if (item[property] === "") {
            item[property] = "NULL";
          }

          if (
            Number(item[property]) === item[property] &&
            item[property] % 1 !== 0
          ) {
            item[property] = Number(item[property]).toFixed(2);
          }
        });
    }
    setDataTableRowValues(_data);
  };
  const setDataBasedOnSearch = (e) => {
    if (e?.data) {
      if (
        currentCountry.toUpperCase() !==
        MirrorDataPayload?.country.toUpperCase()
      ) {
        if (e.data && e.data.length > 0) {
          e?.data.forEach((obj) => {
            if (obj.hasOwnProperty("HS_CODE")) {
              obj.HS_CODE = sanitizeHsCodeDigitBoundaries(
                obj?.HS_CODE,
                filteredTaxonomy?.hs_code_digit_classification
              );
            }
          });
        }
      }
      setLoading(false);
      setAllRecord(e?.data);
      setSearchValueData(e);
      setVisibleData(true);
      setTableHeaderAndData(e, true);
      setDisplayRec(true);
      setIsFilterLoader(false);
      // setIsFiltersShown(true);
    } else {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData?.SOMETHING_WENT_WRONG,
      });
      setIsFilterLoader(false);
    }
  };
  const filterHSCodeAndMonth = (
    arrValueHsCodeData,
    arrValueMonthData,
    isCheckedEmpty,
    isFilter,
    filterData
  ) => {
    const groupedHsCodeData = {};

    arrValueHsCodeData.forEach((item) => {
      const parentId = item._id.toString().substring(0, 2);
      const childId = item._id.toString().substring(0, 4);
      let totalSum = item.totalSum;
      let subCount = 0;
      if (
        isFilter &&
        Object.keys(filterData)?.length > 0 &&
        filterData[FILTER_OPTIONS?.FILTER_HS_CODE]?.length > 0
      ) {
        let object = filterData[FILTER_OPTIONS?.FILTER_HS_CODE]?.find(
          (obj) => obj?._id.toString() === item._id.toString()
        );
        totalSum =
          object !== undefined && Object.keys(object).length > 0
            ? object?.totalSum
            : 0;
        subCount =
          object !== undefined && Object.keys(object).length > 0
            ? object?.count
            : 0;
      }
      if (!groupedHsCodeData[parentId]) {
        groupedHsCodeData[parentId] = {
          _id: parentId,
          isChecked: false,
          children: [],
          count: null,
          totalSum: null,
          subCount: null,
        };
      }

      const parentObj = groupedHsCodeData[parentId];

      if (!parentObj.children.find((child) => child._id === childId)) {
        parentObj.children.push({
          _id: childId,
          isChecked: false,
          children: [],
          count: null,
          subCount: null,
          totalSum: null,
        });
        parentObj.count += item?.count;
        parentObj.totalSum += totalSum;
        parentObj.subCount += subCount;
      } else {
        parentObj.count += item?.count;
        parentObj.totalSum += totalSum;
        parentObj.subCount += subCount;
      }

      let childObj = parentObj.children.find((child) => child._id === childId);
      childObj.children.push({
        _id: item._id.toString(),
        isChecked:
          CheckedData &&
          CheckedData.hasOwnProperty("HS_CODE") &&
          Object.keys(CheckedData["HS_CODE"])?.length > 0 &&
          Object.keys(CheckedData["HS_CODE"]).includes(item._id.toString()) &&
          !isCheckedEmpty
            ? true
            : false,
        count: item.count,
        totalSum: totalSum,
        subCount: subCount,
      });
      childObj.count += item.count;
      childObj.totalSum += totalSum;
      childObj.subCount += subCount;
    });

    const groupedMonthData = {};

    arrValueMonthData.forEach((item) => {
      const [year, month] = item._id.split("-");
      let totalSum = item.totalSum;
      let subCount = 0;
      let isChecked = false;
      if (
        isFilter &&
        Object.keys(filterData)?.length > 0 &&
        filterData[FILTER_OPTIONS?.FILTER_MONTH]?.length > 0
      ) {
        let object = filterData[FILTER_OPTIONS?.FILTER_MONTH]?.find(
          (obj) => obj?._id.toString() === item._id.toString()
        );
        totalSum =
          object !== undefined && Object.keys(object).length > 0
            ? object?.totalSum
            : 0;
        subCount =
          object !== undefined && Object.keys(object).length > 0
            ? object?.count
            : 0;
        isChecked =
          object !== undefined &&
          Object.keys(object).length > 0 &&
          CheckedData[FILTER_OPTIONS?.MONTH] &&
          CheckedData[FILTER_OPTIONS?.MONTH][item?._id]
            ? true
            : false;
      }
      if (!groupedMonthData[year]) {
        groupedMonthData[year] = {
          _id: year,
          isChecked: false,
          children: [],
          count: 0,
          totalSum: 0,
          subCount: 0,
        };
      }

      groupedMonthData[year].count += item.count;
      groupedMonthData[year].totalSum += totalSum;
      groupedMonthData[year].subCount += subCount;

      const monthName = getMonthName(parseInt(month));
      groupedMonthData[year].children.push({
        _id: monthName,
        isChecked: isChecked,
        count: item.count,
        totalSum: totalSum,
        subCount: subCount,
      });
    });
    let hsCode = Object.values(groupedHsCodeData)?.map((ele) => {
      ele?.children &&
        ele?.children?.length > 0 &&
        ele?.children?.map((child) => {
          child?.children &&
            child?.children?.length > 0 &&
            child?.children?.map((subChild) => {
              return subChild;
            });
          child.isChecked = getChildValue(child?.children);
          return child;
        });
      ele.isChecked = getChildValue(ele?.children) ? true : false;
      return ele;
    });
    let month = Object.values(groupedMonthData)?.map((ele) => {
      ele?.children &&
        ele?.children?.length > 0 &&
        ele?.children?.map((child) => {
          return child;
        });
      ele.isChecked = getChildValue(ele?.children) ? true : false;
      return ele;
    });
    setPairForTreeView({
      HS_CODE: hsCode ?? [],
      MONTH: month ?? [],
    });
  };
  const setFilterDataBasedOnSearch = (
    e,
    isCheckedEmpty,
    isFilter,
    filterData
  ) => {
    if (e) {
      setInitialAllFilterValues(e?.filter);
      if (
        currentCountry.toUpperCase() !==
        MirrorDataPayload?.country.toUpperCase()
      ) {
        e?.filter?.FILTER_HS_CODE &&
          e?.filter?.FILTER_HS_CODE?.length &&
          e?.filter?.FILTER_HS_CODE?.forEach((e) => {
            e._id = sanitizeHsCodeDigitBoundaries(
              e?._id.toString(),
              filteredTaxonomy?.hs_code_digit_classification,
              true
            );
          });
      }
      let hsCode = [];
      let month = [];

      if (e?.filter && e?.filter?.FILTER_HS_CODE) {
        hsCode = [...e?.filter?.FILTER_HS_CODE];
      }
      if (e?.filter && e?.filter?.FILTER_MONTH) {
        month = [...e?.filter?.FILTER_MONTH];
      }
      filterHSCodeAndMonth(hsCode, month, isCheckedEmpty, isFilter, filterData);
      // setLoading(false);
      setIsFilterLoader(false);
    } else {
      setLoading(false);
      setIsFilterLoader(false);

      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData?.SOMETHING_WENT_WRONG,
      });
    }
  };
  const showHsCodeError = () => {
    setError(true);
    setErrorMsg(hsCodeSelectLimit);
    const time = setTimeout(() => {
      setError(false);
      setErrorMsg({});
      clearTimeout(time);
    }, 2000);
  };
  const addButton = () => {
    let array = [...advanceSearchComponent];
    array.push(advanceSearchDefaultObj);
    setAdvanceSearchComponent(array);
    let emptyArrayForErr = [...emptyErrorAdvanceSearch];
    emptyArrayForErr.push({ advancedSearch: false });
    setEmptyErrorAdvanceSearch(emptyArrayForErr);
  };
  const handleSelectChange = (e) => {
    setInputValues(null);
    setSelectedOptionForSearchTerm(null);
    setSearchOptionForMain([]);
    let data = searchField?.searchOption.find(
      (item) => item?.field === e?.target?.value
    );
    let options = [];
    let _selectedOperator = "";
    if (data?.searchType && data?.searchType?.length > 0) {
      options =
        data?.searchType?.length > 0 &&
        data?.searchType?.map((e) => {
          return { value: e?.type, title: e?.type };
        });
      _selectedOperator = options[0]?.value;
    }
    setSearchField({
      ...searchField,
      getSelectExploreField: e?.target?.value,
      getValuesForSelectDropdown: data,
      operatorOptions: options,
      selectedOperator: _selectedOperator,
    });
  };
  const handleChangeForSelectedDropdownValue = (value) => {
    if (value === null) {
      setInputValues(null);
      setSelectedOptionForSearchTerm(null);
      setSearchOptionForMain([]);
    } else {
      if (
        SELECT_OPTIONS_VALIDATE_FIELD?.includes(
          searchField?.getSelectExploreField
        )
      ) {
        const twoDigitHSCodes = [];
        value.forEach((item) => {
          const _twoDigitHSCode = item?._id.toString();
          if (
            _twoDigitHSCode?.length == 2 &&
            !isNaN(parseInt(_twoDigitHSCode))
          ) {
            twoDigitHSCodes.push(item);
          }
        });
        if (twoDigitHSCodes?.length > 5) {
          showHsCodeError();
        } else {
          setInputValues(value);
          setSelectedOptionForSearchTerm(value);
        }
      } else {
        setInputValues(value);
        setSelectedOptionForSearchTerm(value);
      }
    }
  };

  const getSearchPayload = (inputValue, type, index) => {
    const dividedDateRange = divideDateRange(
      dateRange?.startMonthDate,
      dateRange?.endMonthDate,
      analyzeSpecification?.data_end_date
    );
    let searchPayload = {
      countryCode: currentCountryCode,
      dateField: analyzeSpecification?.explore_aggregation?.sortTerm,
      endDate: currentWorkspaceEndDate,
      searchField: searchField?.getValuesForSelectDropdown?.field,
      searchTerm: inputValue,
      startDate: currentWorkspaceStartDate,
      tradeType: currentTradeType,
      hs_code_digit_classification:
        filteredTaxonomy?.hs_code_digit_classification,
      workspaceBucket: "",
      dividedDateRange: dividedDateRange,
      country: currentCountry,
      workspaceQuery: true,
      workspaceId: workspaceId,
    };

    if (searchField?.getValuesForSelectDropdown?.field === "HS_CODE") {
      const converHsCode = convertDataIntoLeftAndRightValue(
        [inputValue],
        filteredTaxonomy?.hs_code_digit_classification,
        mirrorData
      );
      if (
        converHsCode &&
        converHsCode?.fieldValueArr &&
        converHsCode?.fieldValueArr?.length > 0
      ) {
        searchPayload.fieldValueRight =
          converHsCode?.fieldValueArr[0]?.fieldValueRight;
        searchPayload.fieldValueLeft =
          converHsCode?.fieldValueArr[0]?.fieldValueLeft;
      }
    }
    if (type === SEARCHTYPE?.advancedSearch) {
      const obj = advanceSearchComponent[index];
      searchPayload.searchField = obj.field;
      searchPayload.countryCode = currentCountry;
    } else {
      searchPayload.searchField =
        searchField?.getValuesForSelectDropdown?.field;
    }
    if (dividedDateRange) {
      if (dividedDateRange.length === 2) {
        searchPayload.dateExpression = 2;
      } else {
        if (dividedDateRange && dividedDateRange[0]) {
          const obj = dividedDateRange[0];
          if (obj.hasOwnProperty("leftFieldvalueHot")) {
            searchPayload.dateExpression = 1;
          } else {
            searchPayload.dateExpression = 0;
          }
        }
      }
    }
    return searchPayload;
  };

  const loadOptions = async (inputValue, type, index) => {
    let searchPayload;
    if (inputValue !== "") {
      if (type === SEARCHTYPE?.advancedSearch) {
        searchPayload = getSearchPayload(inputValue, type, index);

        let dataSearchTerm = await getWorkspaceSearchTermForSearchWithCancelReq(
          searchPayload
        ).then((e) => {
          if (e?.data && e?.data?.length > 0) {
            setReactSelectAdvanceLoading(false);
            return e?.data;
          } else {
            setReactSelectAdvanceLoading(false);
            return [];
          }
        });

        if (searchField?.getSelectExploreField === "HS_CODE") {
          dataSearchTerm = appendZeroAtStart(dataSearchTerm);
        }
        if (
          advanceSearchComponent &&
          dataSearchTerm &&
          Array.isArray(dataSearchTerm) &&
          (ADD_USER_SEARCH_IN_SEARCH_OPTIONS?.includes(
            advanceSearchComponent[index]?.field
          ) ||
            (advanceSearchComponent[index]?.field?.includes("PROD") &&
              advanceSearchComponent[index]?.field?.includes("DESC")))
        ) {
          if (
            ADD_USER_SEARCH_IN_SEARCH_OPTIONS_VALIDATE_FIELD?.includes(
              advanceSearchComponent[index]?.field
            )
          ) {
            if (inputValue?.length >= 2) {
              dataSearchTerm.unshift({
                _id: inputValue,
              });
            }
          } else {
            dataSearchTerm.unshift({
              _id: inputValue,
            });
          }
        }
        if (type === SEARCHTYPE?.advancedSearch) {
          const dataOptions = [...defaultOptionForSearchState];
          dataOptions[index] = {
            searchValueState: dataSearchTerm,
          };
          setDefaultOptionForAdvancedSearch(dataOptions);
        } else {
          setSearchOptionForMain(dataSearchTerm);
        }
        return dataSearchTerm;
      } else {
        if (
          searchField?.searchOption &&
          searchField?.searchOption?.length > 0
        ) {
          searchPayload = getSearchPayload(inputValue, type, index);

          if (selectTrade?.isBlFlag === "true") {
            searchPayload.blCountry = currentCountry;
          }
          let dataSearchTerm =
            await getWorkspaceSearchTermForSearchWithCancelReq(
              searchPayload
            ).then((e) => {
              if (e?.data && e?.data?.length > 0) {
                setReactSelectLoading(false);
                return e?.data;
              } else {
                setReactSelectLoading(false);
                return [];
              }
            });
          if (searchField?.getSelectExploreField === "HS_CODE") {
            dataSearchTerm = appendZeroAtStart(dataSearchTerm);
          }
          if (
            ADD_USER_SEARCH_IN_SEARCH_OPTIONS?.includes(
              searchField?.getSelectExploreField
            ) &&
            dataSearchTerm &&
            Array.isArray(dataSearchTerm)
          ) {
            if (
              ADD_USER_SEARCH_IN_SEARCH_OPTIONS_VALIDATE_FIELD?.includes(
                advanceSearchComponent[index]?.field
              )
            ) {
              if (inputValue?.length >= 2) {
                dataSearchTerm.unshift({
                  _id: inputValue,
                });
              }
            } else {
              dataSearchTerm.unshift({
                _id: inputValue,
              });
            }
          }
          setSearchOptionForMain(dataSearchTerm);
          return dataSearchTerm;
        }
      }
    }
  };
  const handleInputChangeForSearchTerm = _.debounce(
    (value) => {
      if (value !== "") {
        setReactSelectLoading(true);
        if (searchField?.getSelectExploreField === FILTER_OPTIONS?.HS_CODE) {
          const hsCodeRegex = /^[0-9]+$/;
          if (!hsCodeRegex.test(value)) {
            setReactSelectLoading(false);
            alert("HS CODE Only Allows Numeric Value!");
          } else {
            loadOptions(value, SEARCHTYPE?.search, "");
          }
        } else {
          loadOptions(value, SEARCHTYPE?.search, "");
        }

        if (
          searchField?.getValuesForSelectDropdown?.query ===
            QUERYTYPE?.CHOOSE ||
          IsMultiSelectedField?.includes(
            searchField?.getValuesForSelectDropdown?.field
          ) ||
          (searchField?.getValuesForSelectDropdown?.field?.includes("PROD") &&
            searchField?.getValuesForSelectDropdown?.field?.includes("DESC"))
        ) {
          setInputValues(value);
        } else {
          let obj = {
            _id: value,
          };
          setInputValues(obj);
          setSelectedOptionForSearchTerm(obj);
        }
      }
    },
    searchField?.getSelectExploreField === "PRODUCT_DESCRIPTION" ||
      searchField?.getSelectExploreField === "CUSTOMS_DESCRIPTION" ||
      searchField?.getSelectExploreField === "PRODUCT_DESCRIPTION_EN" ||
      searchField?.getSelectExploreField === "HS_CODE_DESCRIPTION" ||
      searchField?.getValuesForSelectDropdown?.field === "HS_DESCRIPTION" ||
      searchField?.getValuesForSelectDropdown?.field === "HS_CODE" ||
      searchField?.getValuesForSelectDropdown?.field === "CMDT_CODE" ||
      (searchField?.getSelectExploreField?.includes("PROD") &&
        searchField?.getSelectExploreField?.includes("DESC"))
      ? 500
      : 10
  );

  const deleteButton = (index) => {
    const array = [...advanceSearchComponent];
    array.splice(index, 1);
    setAdvanceSearchComponent(array);
    // when click or delete btn delete option array based on index
    const _defaultOptionForSearchState = [...defaultOptionForSearchState];
    _defaultOptionForSearchState.splice(index, 1);
    setDefaultOptionForAdvancedSearch(_defaultOptionForSearchState);
    const emptyErrorArray = [...emptyErrorAdvanceSearch];
    emptyErrorArray.splice(index, 1);
    setEmptyErrorAdvanceSearch(emptyErrorArray);
  };
  const handleAdvanceSearchOption = (e, index, type, queryType) => {
    let _advanceSearchComponent = [...advanceSearchComponent];
    setCurrentAdvanceSearchCmpIndex(index);
    if (e !== "") {
      if (
        [
          ADVANCESEARCHFIELDNAME?.relation,
          ADVANCESEARCHFIELDNAME?.operator,
          ADVANCESEARCHFIELDNAME?.field,
        ].includes(e?.target?.name)
      ) {
        const { name, value } = e.target;
        if (
          [
            ADVANCESEARCHFIELDNAME?.relation,
            ADVANCESEARCHFIELDNAME?.operator,
          ].includes(name)
        ) {
          _advanceSearchComponent[index] = {
            ..._advanceSearchComponent[index],
            [name]: value,
          };
        } else if ([ADVANCESEARCHFIELDNAME?.field].includes(name)) {
          let selectedField =
            advanceSearchFieldOptions?.length &&
            advanceSearchFieldOptions?.find((item) => item.field === value);
          let operatorOptions = [];
          if (selectedField && selectedField?.searchType) {
            operatorOptions = createOperator(selectedField?.searchType);
          }
          _advanceSearchComponent[index] = {
            ..._advanceSearchComponent[index],
            query: selectedField?.query,
            operatorOptions: operatorOptions,
            [name]: value,
            value: "",
            operator:
              operatorOptions &&
              operatorOptions?.length > 0 &&
              operatorOptions[0].type,
          };
        }
      } else {
        if (type === "input" && e !== "" && queryType === QUERYTYPE?.SEARCH) {
          _advanceSearchComponent[index] = {
            ..._advanceSearchComponent[index],
            value: { _id: e },
          };
        } else if (e !== "") {
          if (
            SELECT_OPTIONS_VALIDATE_FIELD?.includes(
              _advanceSearchComponent[index]?.field
            )
          ) {
            const twoDigitHSCodes = [];
            e.forEach((item) => {
              const _twoDigitHSCode = item?._id.toString();
              if (
                _twoDigitHSCode?.length == 2 &&
                !isNaN(parseInt(_twoDigitHSCode))
              ) {
                twoDigitHSCodes.push(item);
              }
            });
            if (twoDigitHSCodes?.length > 5) {
              showHsCodeError();
            } else {
              _advanceSearchComponent[index] = {
                ..._advanceSearchComponent[index],
                value: e,
              };
            }
          } else {
            _advanceSearchComponent[index] = {
              ..._advanceSearchComponent[index],
              value: e,
            };
          }
        }
      }
      setAdvanceSearchComponent(_advanceSearchComponent);
    }
  };
  const handleInputChangeForAdvanceSearch = _.debounce(
    (e, index, type, queryType) => {
      const _advanceSearchComponent = [...advanceSearchComponent];
      if (e !== "") {
        if (type === "input" && e !== "") {
          setReactSelectAdvanceLoading(true);
          if (
            _advanceSearchComponent[index]?.field === FILTER_OPTIONS?.HS_CODE
          ) {
            const hsCodeRegex = /^[0-9]+$/;
            if (!hsCodeRegex.test(e)) {
              setReactSelectAdvanceLoading(false);
              alert("HS CODE Only Allows Numeric Value!");
            } else {
              loadOptions(e, SEARCHTYPE?.advancedSearch, index);
            }
          } else {
            loadOptions(e, SEARCHTYPE?.advancedSearch, index);
          }
          if (
            queryType === QUERYTYPE?.SEARCH &&
            !IsMultiSelectedFieldForAdvanceSearch.includes(
              _advanceSearchComponent[index]?.field
            )
          ) {
            _advanceSearchComponent[index] = {
              ..._advanceSearchComponent[index],
              value: { _id: e },
            };
          }
        } else if (e !== "") {
          _advanceSearchComponent[index] = {
            ..._advanceSearchComponent[index],
            value: e,
          };
        }
        setAdvanceSearchComponent(_advanceSearchComponent);
      }
    },
    IsMultiSelectedFieldForAdvanceSearch.includes(
      advanceSearchComponent[currentAdvanceSearchCmpIndex]?.field
    )
      ? 500
      : 10
  );

  // const handleClickTab = (tab) => {
  //   setSelectedTab(tab);
  // };
  const filterOptionClick = (name) => {
    switch (name) {
      case BUTTON_NAME?.REPORT:
        getPowerBiDashBoardDetails(searchPayloadForPowerBi);
        // if (powerBiDashBoard.accessToken && powerBiDashBoard.datasetsId) {
        //   setIsRecordBtnChecked(!isRecordBtnChecked);
        // } else {
        // setPowerBiModelOpen(true);
        // }
        break;
      case BUTTON_NAME?.REMOVE_DUPLICATE:
        setIsRemoveDuplicateButton(true);
        setIsRemoveDuplicateButtonModal(true);
        break;
      case BUTTON_NAME?.SET_DEFAULT:
        setIsRemoveDuplicateButton(false);
        setIsRemoveDuplicateButtonModal(true);
        break;
      case FILTER_OPTIONS?.VIEW_COLUMNS:
        setHandleOpenFilterModal(true);
        break;
      case FILTER_OPTIONS?.HS_CODE:
        setHeaderTitle(FILTER_OPTIONS?.HS_CODE);
        break;
      case FILTER_OPTIONS?.FILTER_COUNTRY:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_COUNTRY);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_COUNTRY ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_COUNTRY ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_DESTINATION_COUNTRY);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_DESTINATION_COUNTRY ?? []
        );
        setFilterValue(
          initialAllFilterValues?.FILTER_DESTINATION_COUNTRY ?? []
        );
        break;
      case FILTER_OPTIONS?.FILTER_DESTINATION_PORT:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_DESTINATION_PORT);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_DESTINATION_PORT ?? []
        );
        setFilterValue(initialAllFilterValues?.FILTER_DESTINATION_PORT ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_ORIGIN_COUNTRY);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_ORIGIN_COUNTRY ?? []
        );
        setFilterValue(initialAllFilterValues?.FILTER_ORIGIN_COUNTRY ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_ORIGIN_PORT:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_ORIGIN_PORT);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_ORIGIN_PORT ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_ORIGIN_PORT ?? []);
        break;
      case FILTER_OPTIONS?.SEARCH_MODE:
        setHeaderTitle(FILTER_OPTIONS?.SEARCH_MODE);
        break;
      case FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_REPORTING_COUNTRY);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_REPORTING_COUNTRY ?? []
        );
        setFilterValue(initialAllFilterValues?.FILTER_REPORTING_COUNTRY ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_PORT:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_PORT);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_PORT ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_PORT ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_STATE:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_STATE);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_STATE ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_STATE ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_PIN:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_PIN);
        setGetAllFilterValues(initialAllFilterValues?.FILTER_PIN ?? []);
        setFilterValue(initialAllFilterValues?.FILTER_PIN ?? []);
        break;
      case FILTER_OPTIONS?.FILTER_FOREIGN_PORT:
        setHeaderTitle(FILTER_OPTIONS?.FILTER_FOREIGN_PORT);
        setGetAllFilterValues(
          initialAllFilterValues?.FILTER_FOREIGN_PORT ?? []
        );
        setFilterValue(initialAllFilterValues?.FILTER_FOREIGN_PORT ?? []);
        break;
      case FILTER_OPTIONS?.MONTH:
        setHeaderTitle(FILTER_OPTIONS?.MONTH);
        break;
      case FILTER_OPTIONS?.QUANTITY:
        setHeaderTitle(FILTER_OPTIONS?.QUANTITY);
        if (initialAllFilterValues?.FILTER_UNIT_QUANTITY !== undefined) {
          initialAllFilterValues?.FILTER_UNIT_QUANTITY.length > 0 &&
            initialAllFilterValues?.FILTER_UNIT_QUANTITY?.forEach((e) => {
              e._id = e?._id?.toUpperCase();
            });
          setGetAllFilterValues(
            initialAllFilterValues?.FILTER_UNIT_QUANTITY ?? []
          );
          setFilterValue(initialAllFilterValues?.FILTER_UNIT_QUANTITY ?? []);
        } else {
          setGetAllFilterValues([]);
          setFilterValue([]);
        }
        break;
      case FILTER_OPTIONS?.PRICE:
        setHeaderTitle(FILTER_OPTIONS?.PRICE);
        const arr = [];
        if (
          initialAllFilterValues?.FILTER_CURRENCY_PRICE_USD !== undefined &&
          initialAllFilterValues?.FILTER_CURRENCY_PRICE_USD?.length > 0
        ) {
          arr.push(initialAllFilterValues?.FILTER_CURRENCY_PRICE_USD[0]);
        }
        if (
          initialAllFilterValues?.FILTER_CURRENCY_PRICE_INR !== undefined &&
          initialAllFilterValues?.FILTER_CURRENCY_PRICE_INR?.length > 0
        ) {
          arr.push(initialAllFilterValues?.FILTER_CURRENCY_PRICE_INR[0]);
        }
        if (
          initialAllFilterValues?.FILTER_DUTY !== undefined &&
          initialAllFilterValues?.FILTER_DUTY?.length > 0
        ) {
          if (
            initialAllFilterValues?.FILTER_DUTY[0]?.metaTag?.length > 0 &&
            initialAllFilterValues?.FILTER_DUTY[0]?.metaTag[0]?.currency !== ""
          ) {
            arr.push(initialAllFilterValues?.FILTER_DUTY[0]);
          }
        }
        setFilterValue(arr);
        setGetAllFilterValues(arr);
        break;
      case FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL:
        setHeaderTitle(FILTER_OPTIONS?.REMOVE_ORDER_OR_NULL);
        break;
      case BUTTON_NAME?.REMOVE_DUPLICATE:
        setIsRemoveDuplicateButton(true);
        setIsRemoveDuplicateButtonModal(true);
        break;
      case BUTTON_NAME?.SET_DEFAULT:
        setIsRemoveDuplicateButton(false);
        setIsRemoveDuplicateButtonModal(true);
        break;
      default:
        break;
    }
  };
  const applyButtonClicked = (event, title) => {
    setHandleOpenFilterModal(false);
  };
  const handleApplyFilter = (e) => {
    applyButtonClicked(e, headerTitle);
    setApply({
      ...apply,
      [headerTitle]: true,
    });
    setPage(0);
    removeButtonState = {
      ...removeButtonState,
      [headerTitle]: false,
    };
  };

  const RemoveAppliedFilter = (filterName, isRemoveAllBtn, subKeyName) => {
    const all = { ...storeFilteredValues };
    const _CheckedData = { ...CheckedData };
    const header = filterName;

    if (header === FILTER_OPTIONS?.QUANTITY) {
      setSelectedQuantityOrPrice((prev) => ({
        ...prev,
        [header]: "",
      }));

      if (_CheckedData[header]?.[subKeyName] && !isRemoveAllBtn) {
        delete _CheckedData[header][subKeyName];
        delete all[header][subKeyName];

        if (Object.keys(_CheckedData[header]).length === 0) {
          delete _CheckedData[header];
          delete all[header];
        }
      } else if (_CheckedData[header] && isRemoveAllBtn) {
        delete _CheckedData[header];
        delete all[header];
      }
    }
    if (_CheckedData[header] && header !== FILTER_OPTIONS?.QUANTITY) {
      delete _CheckedData[header];
      delete all[header];
    }

    removeButtonState = {
      ...removeButtonState,
      [header]: true,
    };

    setApply({
      ...apply,
      [header]: false,
    });

    setCheckedData(_CheckedData);
    setStoreFilterValues(all);

    if (Object.keys(_CheckedData).length === 0) {
      filterOnSearchClick("Filter");
    }
  };

  const advanceSearchValidation = () => {
    let _emptyErrorAdvanceSearch = [...emptyErrorAdvanceSearch];
    const indexArray = [];
    advanceSearchComponent &&
      advanceSearchComponent?.length > 0 &&
      advanceSearchComponent?.forEach((e, index) => {
        if (e?.value === "" || (e?.value && e?.value.length === 0)) {
          _emptyErrorAdvanceSearch[index] = { advancedSearch: true };
          indexArray.push(index);
          return;
        }
      });
    setEmptyErrorAdvanceSearch(_emptyErrorAdvanceSearch);
    return indexArray;
  };

  const onSearchClick = (refreshType, isCheckedEmpty, removeDuplicate) => {
    if (!removeDuplicate && currentCountry === "INDIA") {
      setIsRemoveDuplicateButton(false);
      setIsChangeRemoveDuplicateButton(false);
    }
    if (intialCall && refreshType !== "Filter") {
      if (
        getSelectedOptionForSearchTerm === null ||
        (getSelectedOptionForSearchTerm &&
          getSelectedOptionForSearchTerm.length === 0)
      ) {
        setIsSearchValueEmpty({
          ...isSearchValueEmpty,
          searchTerm: true,
        });
        return;
      }
      const indexArray = advanceSearchValidation();
      if (indexArray?.length > 0) {
        return;
      }
    }
    onSearchClickFlag = true;
    setIntialCall(true);
    setDisplayRec(true);
    setLoading(true);
    if (removeDuplicate) {
      setLoadingMsg(RemovingDuplicateRecord);
    } else {
      setLoadingMsg(Retrieving_Workspaces);
    }
    setIsRemoveDuplicateButtonModal(false);
    let paramsObj = {};
    const requiredData = getPayloadRelatedData();
    requiredData.isRemoveDuplicateButton = removeDuplicate;
    let isCheckedOnSearch = false;
    if (CheckedData && Object.keys(CheckedData).length > 0) {
      isCheckedOnSearch = true;
      let currentAppliedFilterData = {};
      setCheckedData({});
      if (appliedFilterData.hasOwnProperty(FILTER_OPTIONS?.VIEW_COLUMNS)) {
        currentAppliedFilterData = {
          VIEW_COLUMNS: appliedFilterData[FILTER_OPTIONS?.VIEW_COLUMNS],
        };
      }
      setAppliedFilterData(currentAppliedFilterData);
    }
    paramsObj = packDTParams(false, isCheckedOnSearch, requiredData);
    if (mirrorData) {
      paramsObj.isFromMirrorData = true;
    }
    setSearchPayloadForPowerBi(paramsObj);
    onSearchWorkspace(paramsObj)
      .then((res) => {
        if (res?.data?.message) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: res?.data?.message,
          });
        } else if (res?.status === 401) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: ErrorData?.SESSION_TIMEOUT,
          });
        } else {
          if (res && res?.length > 0) {
            if (res[1]) {
              if (res[1]?.data) {
                let e = res[1].data;
                let _monthYearArray = [];
                if (monthYearArray) {
                  _monthYearArray = [...monthYearArray];
                }
                if (Object.keys(e?.filter).length !== 0) {
                  for (let element of e?.filter?.FILTER_MONTH) {
                    let object = {
                      year: parseInt(element?._id.split("-")[0]),
                      months: [parseInt(element?._id.split("-")[1])],
                    };
                    const index = _monthYearArray.findIndex(
                      (e) => e.year === object.year
                    );
                    if (index === -1) {
                      _monthYearArray.push(object);
                    } else {
                      _monthYearArray[index]["months"].push(object?.months[0]);
                    }
                  }
                }
                setMonthYearArray(_monthYearArray);
                setGetAllSplitFilters(e);
                setAllFilterValuesWithCountsArr(e?.filter);
                setAllFilterValueArray(e);
                setFilterDataBasedOnSearch(e, true);

                // let _currentFilterCountForGraph = { currentFilterCountForGraph2 };
                // let _currentFilterCountForGraph = {
                //   currentFilteredCountryCount:
                //     e?.filter["FILTER_COUNTRY"] &&
                //     e?.filter["FILTER_COUNTRY"].length,
                //   currentFilteredPortCount:
                //     e?.filter["FILTER_PORT"] && e.filter["FILTER_PORT"].length,
                // };
                // setCurrentFilterCountForGraph2(_currentFilterCountForGraph);
              }
            }
            if (res[0]) {
              if (res[0]?.data) {
                if (res[0]?.data?.message) {
                  setLoading(false);
                  setError(true);
                  setErrorMsg({
                    title: "Warning",
                    content: res[0]?.data?.message,
                  });
                } else {
                  setDataBasedOnSearch(res[0]?.data);
                }
              } else {
                throw new Error();
              }
            }
            if (res[2]) {
              setSummaryDetails(res[2].data);
            } else {
              throw new Error();
            }
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
    // getPowerBiDashBoardDetails(paramsObj);
  };
  // useEffect(() => {
  //   setCurrentFilterCountForGraph({
  //     ...currentFilterCountForGraph2,
  //     ...currentFilterCountForGraph3,
  //   });
  // }, [currentFilterCountForGraph2, currentFilterCountForGraph3]);
  const setDataBasedOnFilter = (e, isCheckedEmpty) => {
    if (e?.data) {
      if (
        currentCountry.toUpperCase() !==
        MirrorDataPayload?.country.toUpperCase()
      ) {
        if (e.data && e.data.length > 0) {
          e?.data.forEach((obj) => {
            if (obj.hasOwnProperty("HS_CODE")) {
              obj.HS_CODE = sanitizeHsCodeDigitBoundaries(
                obj?.HS_CODE,
                filteredTaxonomy?.hs_code_digit_classification
              );
            }
          });
        }
      }
      setAllRecord(e?.data);
      setSearchValueData(e);
      setTableHeaderAndData(e, false);
      setLoading(false);
      // setIsFiltersShown(true);
      setVisibleData(true);
      setIsFilterLoader(false);
      setDisplayRec(true);
    } else {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData?.SOMETHING_WENT_WRONG,
      });
      setIsFilterLoader(false);
    }
  };
  const setSummaryDetails = (data) => {
    setSummaryData(data);
    translateButtonClick();
  };
  const filterOnSearchClick = (refreshType, isCheckedEmpty) => {
    if (intialCall && refreshType !== "Filter") {
      if (
        getSelectedOptionForSearchTerm === null ||
        (getSelectedOptionForSearchTerm &&
          getSelectedOptionForSearchTerm.length === 0)
      ) {
        setIsSearchValueEmpty({
          ...isSearchValueEmpty,
          searchTerm: true,
        });
        return;
      }
      const indexArray = advanceSearchValidation();
      if (indexArray?.length > 0) {
        return;
      }
    }
    onSearchClickFlag = true;
    setIntialCall(true);
    setDisplayRec(true);
    setLoading(true);
    setLoadingMsg(Retrieving_Workspaces);
    let paramsObj = {};
    const requiredData = getPayloadRelatedData();
    let isCheckedOnSearch = false;
    const _CheckedData = { ...CheckedData };
    for (const [key, value] of Object.entries(_CheckedData)) {
      if (Object.keys(value).length === 0) {
        delete _CheckedData[key];
        setCheckedData(_CheckedData);
        setStoreFilterValues(_CheckedData);
      }
    }
    paramsObj = packDTParams(false, isCheckedOnSearch, requiredData);
    if (mirrorData) {
      paramsObj.isFromMirrorData = true;
    }
    setSearchPayloadForPowerBi(paramsObj);
    onSearchWorkspace(paramsObj)
      .then((res) => {
        if (res?.data?.message) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: res?.data?.message,
          });
        } else if (res?.status === 401) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: ErrorData?.SESSION_TIMEOUT,
          });
        } else {
          if (res && res?.length > 0) {
            if (res[1] && res[1]?.data) {
              let e = res[1].data;
              let _monthYearArray = [];
              if (monthYearArray) {
                _monthYearArray = [...monthYearArray];
              }
              if (Object.keys(e?.filter).length !== 0) {
                for (let element of e?.filter?.FILTER_MONTH) {
                  let object = {
                    year: parseInt(element?._id.split("-")[0]),
                    months: [parseInt(element?._id.split("-")[1])],
                  };
                  const index = _monthYearArray.findIndex(
                    (e) => e.year === object.year
                  );
                  if (index === -1) {
                    _monthYearArray.push(object);
                  } else {
                    _monthYearArray[index]["months"].push(object?.months[0]);
                  }
                }
              }
              setMonthYearArray(_monthYearArray);
              setGetAllSplitFilters(e);
              setAllFilterValuesWithCountsArr(e?.filter);
              let filter = { ...getAllFilterValueArray };
              setFilterDataBasedOnSearch(
                filter,
                isCheckedEmpty,
                true,
                e?.filter
              );
              // let _currentFilterCountForGraph = { currentFilterCountForGraph2 };
              // let _currentFilterCountForGraph = {
              //   currentFilteredCountryCount:
              //     e?.filter["FILTER_COUNTRY"] &&
              //     e?.filter["FILTER_COUNTRY"].length,
              //   currentFilteredPortCount:
              //     e?.filter["FILTER_PORT"] && e.filter["FILTER_PORT"].length,
              // };

              // setCurrentFilterCountForGraph2(_currentFilterCountForGraph);
            } else {
              throw new Error();
            }
            if (res[0] && res[0]?.data) {
              // setSelectedTab(1);
              setDataBasedOnFilter(res[0]?.data, isCheckedEmpty);

              // let _currentFilterCountForGraph = { ...currentFilterCountForGraph };
              // let _currentFilterCountForGraph = {
              //   currentFilteredImporterCount:
              //     res[0]?.data?.summary?.SUMMARY_BUYERS,
              //   currentFilteredExporterCount:
              //     res[0]?.data?.summary?.SUMMARY_SELLERS,
              //   currentFilteredHsCodeCount:
              //     res[0]?.data?.summary?.SUMMARY_HS_CODE,
              // };
              // setCurrentFilterCountForGraph3(_currentFilterCountForGraph);
            } else {
              throw new Error();
            }
            if (res[2]) {
              setSummaryDetails(res[2].data);
            } else {
              throw new Error();
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
    // getPowerBiDashBoardDetails(paramsObj);
  };

  const handleToggleFilters = () => {
    setIsFiltersShown(!isFiltersShown);
  };
  const handleDescendingAndAscendingOrder = (column, type) => {
    let obj = descendingAndAscending;
    Object.keys(obj).length > 0 &&
      Object.keys(obj).forEach((e) => {
        obj[e] = e === column ? type : SORTINGTYPE?.default;
      });

    setDescendingAndAscending(obj);
    handleAscDesc();
  };
  function handleAscDesc() {
    if (isDisplayRec) {
      onSearchClick("Filter");
    }
  }
  useEffect(() => {
    let _appliedFilterData = { ...appliedFilterData };
    if (_appliedFilterData && Object.keys(_appliedFilterData)?.length) {
      delete _appliedFilterData[FILTER_OPTIONS?.VIEW_COLUMNS];
    }
    if (apply && Object.keys(apply)?.length > 0) {
      for (const [keys, values] of Object.entries(apply)) {
        if (!values) {
          delete _appliedFilterData[keys];
        }
      }
    }
    if (JSON.stringify(CheckedData) !== JSON.stringify(_appliedFilterData)) {
      setCheckedData(_appliedFilterData);
    }
  }, [apply]);

  useEffect(() => {
    if (headerTitle !== FILTER_OPTIONS?.VIEW_COLUMNS) {
      setAppliedFilterData(storeFilteredValues);
    }
  }, [storeFilteredValues]);

  if (appliedFilterData) {
    for (const [key, value] of Object.entries(appliedFilterData)) {
      for (const [keys, values] of Object.entries(value)) {
        if (!values && values !== 0) {
          const _appliedFilterData = appliedFilterData;
          delete _appliedFilterData[key][keys];
          setAppliedFilterData(_appliedFilterData);
        }
      }
    }
  }
  useEffect(() => {
    if (CheckedData && Object.keys(CheckedData).length > 0) {
      filterOnSearchClick("Filter");
    }
  }, [CheckedData]);

  const addViewColumn = (payload) => {
    addViewColumns(payload).then((res) => {
      if (res) {
        getAccountViewColumns(filteredTaxonomy?._id);
        setApprovSuccessModal(true);
        setApprovSuccessModalData(ApprovSuccess_viewColumns);
        setTimeout(() => {
          setApprovSuccessModal(false);
        }, 2000);
      }
      setHandleOpenFilterModal(false);
    });
  };
  const handleChangePage = (newPage) => {
    onSearchClickFlag = true;
    // setLoading(true);
    setLoadingMsg(FetchData);
    let offset = (newPage - 1) * rowsPerPage;

    if (offset + 10 > 1000) {
      setLoading(false);
      setError(true);
      setErrorMsg(RecordLimit);
    } else {
      setPage(newPage - 1);
      setDraw(draw + 1);
      // const startRecord = (newPage - 1) * rowsPerPage;
      // const data = allRecord.slice(startRecord, startRecord + rowsPerPage);
      const data = allRecord;
      for (const item of data) {
        item &&
          Object.keys(item).length > 0 &&
          Object.keys(item).forEach((property) => {
            if (item[property] === "") {
              item[property] = "NULL";
            }

            if (
              Number(item[property]) === item[property] &&
              item[property] % 1 !== 0
            ) {
              item[property] = Number(item[property]).toFixed(2);
            }
          });
      }
      setDataTableRowValues(data);
      translateButtonClick();
    }
    // const requiredData = getPayloadRelatedData();
    // let paramsObj = packDTParams(false, false, requiredData);
    // paramsObj.start = offset;
    // paramsObj.length = rowsPerPage;
    // paramsObj.draw = draw + 1;
    // workSpaceRecord(paramsObj)
    //   .then((e) => {
    //     if (e?.status === 401 || e?.status === 500) {
    //       throw throwError(e);
    //     } else {
    //       if (e?.data) {
    //         setLoading(false);
    //         setSearchValueData(e);
    //         setSummaryData(e?.summary);
    //         setVisibleData(true);
    //         setTableHeaderAndData(e, false);
    //       } else {
    //         throw new Error();
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     catchBlock(err);
    //   });
  };
  const handleChangeRowsPerPage = (value) => {
    setPage(0);
    const row = Number(value);
    onSearchClickFlag = true;
    // setLoading(true);
    setLoadingMsg(FetchData);
    setRowsPerPage(row);
    setDraw(draw + 1);
    // const startRecord = 0 * row;
    // const data = allRecord.slice(startRecord, row);
    const data = allRecord;
    for (const item of data) {
      item &&
        Object.keys(item).length > 0 &&
        Object.keys(item).forEach((property) => {
          if (item[property] === "") {
            item[property] = "NULL";
          }
          if (
            Number(item[property]) === item[property] &&
            item[property] % 1 !== 0
          ) {
            item[property] = Number(item[property]).toFixed(2);
          }
        });
    }
    setDataTableRowValues(data);
    translateButtonClick();
    // const requiredData = getPayloadRelatedData();
    // let paramsObj = packDTParams(false, false, requiredData);
    // paramsObj.start = 0;
    // paramsObj.length = value;
    // paramsObj.draw = draw + 1;
    // workSpaceRecord(paramsObj)
    //   .then((e) => {
    //     if (e?.status === 401 || e?.status === 500) {
    //       throw throwError(e);
    //     } else {
    //       if (e?.data) {
    //         setLoading(false);
    //         setSearchValueData(e);
    //         setSummaryData(e?.summary);
    //         setVisibleData(true);
    //         setTableHeaderAndData(e, false);
    //       } else {
    //         throw new Error();
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     catchBlock(err);
    //   });
  };

  // Excel Download
  const handleDownloadExcel = async () => {
    setLoading(true);
    setLoadingMsg(DownLoad_Excel_Notify);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000);
    const requiredData = getPayloadRelatedData();
    let paramsObj = packDTParams(false, false, requiredData);
    let allFields = paramsObj.allFields.filter(
      (e) => !e.includes("RECORDS_TAG")
    );
    if (paramsObj.country === "Silkroute") {
      paramsObj.country = "SilkRouteExim";
    } else if (paramsObj.country === "Mirror_table") {
      paramsObj.country = "MirrorTable";
    }
    paramsObj.allFields = allFields;
    paramsObj.draw = 0;
    paramsObj.start = 0;
    paramsObj.length = 10;
    paramsObj.type = "filteredWorkspace";
    paramsObj.workspaceName = currentSelectedWorkSpace.name;

    downloadWorkspaceRecord(paramsObj)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          setLoading(false);
          if (e?.status === 202) {
            setError(true);
            setErrorMsg({
              title: "Info",
              content:
                "Workspace Download Started...We will notify you once done !",
            });
          } else if (e?.status === 200) {
            const url = e.data.download_url;
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `${currentSelectedWorkSpace.name}.xlsx`;
            anchor.click();
            setError(true);
            setErrorMsg(DownLoad_Excel_SuccessFull);
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };
  const removeDuplicateBtnClick = () => {
    setIsChangeRemoveDuplicateButton(!ChangeRemoveDuplicateButton);
    onSearchClick("Filter", false, isRemoveDuplicateButton);
  };

  const handleClearCheckedFilterData = () => {
    setCheckedData({});
    setAppliedFilterData({});
  };
  return (
    <div className="paddingAllLeftRight" id="workSpace-main-container">
      {dashboardLoading && <PercentageLoader value={progress} />}
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {error && (
        <ErrorLoading errorMsg={errorMsg} open={error} setOpen={setError} />
      )}
      {powerBiModelOpen && (
        <ErrorLoading
          errorMsg={{
            title: "Info",
            content: "We are working on dashboard for this country",
          }}
          open={powerBiModelOpen}
          setOpen={setPowerBiModelOpen}
        />
      )}
      {isRemoveDuplicateButtonModal && (
        <ModalComponent
          open={isRemoveDuplicateButtonModal}
          setOpen={setIsRemoveDuplicateButtonModal}
          isRemoveDuplicateButton={isRemoveDuplicateButton}
          setIsRemoveDuplicateButton={setIsRemoveDuplicateButton}
          header={
            !isRemoveDuplicateButton ? "Set Default Data" : "Remove Duplicate"
          }
          modalBody={
            <span
              style={{
                fontSize: "18px",
                fontWeight: 600,
                color: "rgb(50, 58, 70)",
              }}
            >
              {!isRemoveDuplicateButton
                ? "Set Default Data?"
                : "Are you sure you want to remove duplicate data?"}
            </span>
          }
          width="600px"
          saveText={
            !isRemoveDuplicateButton ? "Set Default" : "Remove Duplicate"
          }
          saveButtonShown={true}
          saveButtonColor="#005D91"
          fromRemoveDuplicate={true}
          savedClicked={removeDuplicateBtnClick}
        />
      )}
      <ApprovedSuccess
        Open={approvSuccessModal}
        title={approvSuccessModalData.title}
        footerTitle={approvSuccessModalData.footerTitle}
      />
      {isRecordBtnChecked && (
        <div
          className="row-dashbored"
          style={{ padding: "20px 0px 10px 0px " }}
        >
          <h4 className="text" style={{ margin: "0px" }}>
            Analyze Workspace
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              {currentWorkspaceName}
            </span>
          </h4>
        </div>
      )}
      {!isRecordBtnChecked && (
        <PowerBIReport
          powerBiDashBoard={powerBiDashBoard}
          setIsRecordBtnChecked={setIsRecordBtnChecked}
        />
      )}
      {isRecordBtnChecked && (
        <div
          className="workSpace-search-container"
          style={{ padding: "10px 0px 10px 0px " }}
        >
          <SearchComponent
            handleSelectChange={handleSelectChange}
            handleChangeForSelectedDropdownValue={
              handleChangeForSelectedDropdownValue
            }
            handleInputChangeForSearchTerm={handleInputChangeForSearchTerm}
            searchField={searchField}
            setSearchField={setSearchField}
            reactSelectLoading={reactSelectLoading}
            getSearchOption={getSearchOption}
            getSelectedOptionForSearchTerm={getSelectedOptionForSearchTerm}
            addButton={addButton}
            advanceSearchComponent={advanceSearchComponent}
            currentCountry={currentCountry}
            currentTradeType={currentTradeType}
            isBlFlag={isBlFlag}
            selectTrade={selectTrade}
            onSearchClick={onSearchClick}
            isSearchValueEmpty={isSearchValueEmpty}
            isWorkSpace={true}
            workSpaceBtnClass="workSpaceBtnClass"
            dateRange={dateRange}
            advanceSearchFieldOptions={advanceSearchFieldOptions}
            handleClearCheckedFilterData={handleClearCheckedFilterData}
          />
        </div>
      )}
      {advanceSearchComponent?.length > 0 && (
        <div className="workspace-advanceSearch-container">
          {advanceSearchComponent?.map((e, index) => {
            return (
              <div>
                <AdvanceSearchComponent
                  index={index}
                  reactSelectAdvanceLoading={reactSelectAdvanceLoading}
                  addButton={addButton}
                  advanceSearchComponent={advanceSearchComponent}
                  advanceSearchValue={advanceSearchComponent[index]}
                  advanceSearchFieldOptions={advanceSearchFieldOptions}
                  handleAdvanceSearchOption={handleAdvanceSearchOption}
                  defaultOptionForSearchState={
                    defaultOptionForSearchState[index]
                  }
                  handleInputChangeForAdvanceSearch={
                    handleInputChangeForAdvanceSearch
                  }
                  deleteButton={deleteButton}
                  onSearchClick={onSearchClick}
                  emptyErrorAdvanceSearch={emptyErrorAdvanceSearch}
                  workSpaceBtnClass="workSpaceBtnClass"
                />
              </div>
            );
          })}
        </div>
      )}

      {isVisibleData && isRecordBtnChecked && (
        <>
          <div className="summary-btn-container-dashboard">
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="summary-dashboard"
            >
              <Summary data={summaryData} />
            </div>
            <WorkSpaceButton
              handleDownloadExcel={handleDownloadExcel}
              filterOptionClick={filterOptionClick}
              currentCountry={currentCountry}
              ChangeRemoveDuplicateButton={ChangeRemoveDuplicateButton}
              // dashboardLoading={dashboardLoading}
            />
          </div>
          {/* <div className="workspace-tab"></div>{" "} */}
          <ShipmentNewUI
            dataEndDate={analyzeSpecificationData?.data?.data_end_date}
            currentCountry={currentCountry}
            currentTradeType={currentTradeType}
            CLIENT_ACCOUNT_ID={props?.CLIENT_ACCOUNT_ID}
            getSearchValueData={getSearchValueData}
            dateRange={dateRange}
            filteredTaxonomy={filteredTaxonomy}
            dataTableHeader={dataTableHeader}
            isFiltersShown={isFiltersShown}
            searchField={searchField}
            rowsPerPage={rowsPerPage}
            handleApplyFilter={handleApplyFilter}
            setIsFiltersShown={setIsFiltersShown}
            filterOptionClick={filterOptionClick}
            headerTitle={headerTitle}
            keyValuePairForTreeview={keyValuePairForTreeview}
            allFilterValuesWithCountsArr={allFilterValuesWithCountsArr}
            getAllFilterValues={getAllFilterValues}
            getFilterValues={getFilterValues}
            setFilterValue={setFilterValue}
            getMaxMinRange={getMaxMinRange}
            setMaxMinRange={setMaxMinRange}
            rangeValue={rangeValue}
            setRangeValue={setRangeValue}
            selectedQuantityOrPriceValue={selectedQuantityOrPriceValue}
            setSelectedQuantityOrPrice={setSelectedQuantityOrPrice}
            lastSelected={lastSelected}
            setLastSelected={setLastSelected}
            storeFilteredValues={storeFilteredValues}
            setStoreFilterValues={setStoreFilterValues}
            CheckedData={CheckedData}
            appliedFilterData={appliedFilterData}
            setPairForTreeView={setPairForTreeView}
            isFilterLoader={isFilterLoader}
            RemoveAppliedFilter={RemoveAppliedFilter}
            apply={apply}
            handleToggleFilters={handleToggleFilters}
            dataTableRowValues={dataTableRowValues}
            page={page}
            descendingAndAscending={descendingAndAscending}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleDescendingAndAscendingOrder={
              handleDescendingAndAscendingOrder
            }
            handleOpenFilterModal={handleOpenFilterModal}
            setHandleOpenFilterModal={setHandleOpenFilterModal}
            setDataTableHeader={setDataTableHeader}
            accountTableHeader={accountTableHeader}
            allHeader={allHeader}
            addViewColumn={addViewColumn}
            viewHeader={viewHeader}
            setViewHeader={setViewHeader}
            isVisibleData={isVisibleData}
            summaryData={summaryData}
            setError={setError}
            setErrorMsg={setErrorMsg}
            setLoadingMsg={setLoadingMsg}
            setLoading={setLoading}
            mirrorData={mirrorData}
            isFromWorkspace={true}
          />
        </>
      )}
    </div>
  );
});

export default AnalyzeWorkSpace;
