// BarChart.js
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CountryStatsBarChart = ({ data }) => {
  const options = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Requests by Country",
    },
    xAxis: {
      categories: data?.map((item) => item?._id),
      title: {
        text: "Country",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Requests",
      },
    },
    series: [
      {
        name: "Requests",
        data: data?.map((item) => item?.requests),
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default CountryStatsBarChart;
