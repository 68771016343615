// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif;
}
.custom-table th,
.custom-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}
.custom-table th {
  background-color: #f4f4f4;
}
.custom-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.custom-table tr:hover {
  background-color: #f1f1f1;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  font-size: 14px;
}
.pagination button.active {
  font-weight: bold;
  background-color: #005d91;
  color: white;
}
.pagination button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}
.pagination button:hover:not(:disabled) {
  background-color: #ddd;
}`, "",{"version":3,"sources":["webpack://./src/Containers/TradeAPIContainer/components/Documentations/CsVToTable.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,mBAAA;EACA,gCAAA;AACF;AACE;;EAEE,aAAA;EACA,gBAAA;EACA,sBAAA;AACJ;AAEE;EACE,yBAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,yBAAA;AAFJ;;AAMA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AAHF;AAKE;EACE,iBAAA;EACA,aAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AAHJ;AAKI;EACE,iBAAA;EACA,yBAAA;EACA,YAAA;AAHN;AAMI;EACE,mBAAA;EACA,sBAAA;AAJN;AAOI;EACE,sBAAA;AALN","sourcesContent":[".custom-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-bottom: 20px;\n  font-family: \"Arial\", sans-serif;\n\n  th,\n  td {\n    padding: 10px;\n    text-align: left;\n    border: 1px solid #ddd;\n  }\n\n  th {\n    background-color: #f4f4f4;\n  }\n\n  tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n\n  tr:hover {\n    background-color: #f1f1f1;\n  }\n}\n\n.pagination {\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n\n  button {\n    padding: 5px 10px;\n    margin: 0 5px;\n    cursor: pointer;\n    border: none;\n    background-color: #f0f0f0;\n    font-size: 14px;\n\n    &.active {\n      font-weight: bold;\n      background-color: #005d91;\n      color: white;\n    }\n\n    &:disabled {\n      cursor: not-allowed;\n      background-color: #ccc;\n    }\n\n    &:hover:not(:disabled) {\n      background-color: #ddd;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
