import React, { useCallback, useEffect, useState } from "react";
import { recentTradeApiActivity } from "../../../../Services/service";
import StickyTable from "../../../../Component/Table/Table";
import ModalComponent from "../../../../Component/Modal/modal";
import "./RecentTradeApiLog.scss";
import moment from "moment";
import { logger } from "../../../../Logger/Logger";
import ErrorData from "../../../../utils/ErrorHandling";
import ErrorLoading from "../../../../Component/ErrorLoader/error";

const API_HEADER = [
  { key: "api_type", title: "Api Type" },
  { key: "created_ts", title: "Query Date" },
  { key: "api_status", title: "API Status" },
  { key: "requested_records", title: "Requested Records" },
  { key: "delivered_records", title: "Delivered Records" },
  { key: "points_deducted", title: "Points Deducted" },
  { key: "response_time", title: "Response Time" },
  { key: "trade_api_request_payload", title: "Payload" },
];

function RecentTradeApiLog() {
  const [apiLogData, setApiLogData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalRecords, setTotalRecord] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [storePayload, setStorePayload] = useState({});
  const [errorMsg, setErrorMsg] = useState({});
  const [error, setError] = useState(false);

  const catchBlock = (err) => {
    const errorForLogger = `TradeApi usage logs:- ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? ErrorData?.SESSION_TIMEOUT
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };

  const fetchApiLogData = () => {
    recentTradeApiActivity()
      .then((res) => {
        if (res && res?.length) {
          setTotalRecord(res?.length);
          const formattedData = res?.map(
            ({
              api_type,
              requested_records,
              api_status,
              response_time,
              delivered_records,
              points_deducted,
              created_ts,
              request_payload,
            }) => ({
              api_type,
              requested_records,
              api_status: api_status?.status,
              response_time,
              delivered_records,
              points_deducted,
              created_ts: moment(created_ts).format("YYYY-MM-DD HH:mm:ss"),
              request_payload,
            })
          );
          setAllData(formattedData);
          const initialData = formattedData?.slice(0, rowsPerPage);
          setApiLogData(initialData);
        }

        if (res?.status === 400 || res?.status === 401 || res?.status === 500) {
          catchBlock(res);
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };

  useEffect(() => {
    fetchApiLogData();
  }, []);

  const handleChangeRowsPerPage = (value) => {
    const newRowsPerPage = parseInt(value);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    const paginatedData = allData?.slice(0, newRowsPerPage);
    setApiLogData(paginatedData);
  };

  const handleChangePage = (event) => {
    const newPage = parseInt(event.target.textContent) - 1;
    setPage(newPage);
    const offset = newPage * rowsPerPage;
    const paginatedData = allData?.slice(offset, offset + rowsPerPage);
    setApiLogData(paginatedData);
  };

  const handlePayloadBtn = (rowData) => {
    setOpenModal(true);
    setStorePayload(rowData?.request_payload);
  };

  const renderPayloadBodyWrapper = useCallback(() => {
    return (
      <div className="payload-container">
        <div className="section">
          <h3>Date Range</h3>
          <div className="section-item">
            <p>
              <strong>Start Date:</strong> {storePayload?.DateRange?.start_date}
            </p>
          </div>
          <div className="section-item">
            <p>
              <strong>End Date:</strong> {storePayload?.DateRange?.end_date}
            </p>
          </div>
        </div>

        <div className="section">
          <h3>Trade Information</h3>
          <div className="section-item">
            <p>
              <strong>Trade Type:</strong> {storePayload?.TradeType}
            </p>
          </div>
          <div className="section-item">
            <p>
              <strong>Trade Country:</strong> {storePayload?.TradeCountry}
            </p>
          </div>
        </div>

        <div className="section">
          <h3>Search Criteria</h3>
          <div className="criteria">
            {storePayload?.SearchCriteria &&
              storePayload?.SearchCriteria?.map((criteria, index) => (
                <div key={index} className="criteria-item">
                  <p>
                    <strong>Filter:</strong> {criteria?.FILTER}
                  </p>
                  <p>
                    <strong>Values:</strong> {criteria?.VALUES?.join(", ")}
                  </p>
                  <p>
                    <strong>Operator:</strong> {criteria?.OPERATOR}
                  </p>
                </div>
              ))}
          </div>
        </div>

        <div className="section">
          <div className="section-item">
            <p>
              <strong>Page Size:</strong> {storePayload?.page_size}
            </p>
          </div>
          <div className="section-item">
            <p>
              <strong>Page Number:</strong> {storePayload?.page_no}
            </p>
          </div>
          <div className="section-item">
            <p>
              <strong>Sort:</strong> {storePayload?.sort}
            </p>
          </div>
          <div className="section-item">
            <p>
              <strong>Sort Type:</strong> {storePayload?.sort_type}
            </p>
          </div>
          <div className="section-item">
            <p>
              <strong>API Endpoint:</strong> {storePayload?.api_endpoint}
            </p>
          </div>
        </div>
      </div>
    );
  }, [storePayload]);

  const dataTable = useCallback(() => {
    return (
      <>
        <StickyTable
          columns={API_HEADER}
          rows={apiLogData}
          showPagination={true}
          totalAvailableData={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          isFromActivity={true}
          height={"100%"}
          handlePayloadBtn={handlePayloadBtn}
        />
      </>
    );
  }, [apiLogData, rowsPerPage, page, totalRecords]);

  return (
    <>
      {error && (
        <ErrorLoading errorMsg={errorMsg} open={error} setOpen={setError} />
      )}
      <ModalComponent
        open={openModal}
        setOpen={setOpenModal}
        header="Trade Api Requested Payload"
        modalBody={<div>{renderPayloadBodyWrapper()}</div>}
        width={"50%"}
      />
      <div style={{ marginTop: "50px" }}>{dataTable()}</div>
    </>
  );
}

export default RecentTradeApiLog;
