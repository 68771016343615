import React, { useState } from "react";
import { marked } from "marked";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./ReadmeRenderer.scss";
import CodeBlockBlack from "../CodeBlockRenderer/CodeBlockBlack";
import toast from "react-hot-toast";
import { API_PAYLOAD } from "./DocumentAPIPayload";
import { API_OVERVIEW } from "./DocumentAPIOverview";
import { API_DOCUMENTATION } from "./DocumentAPIDocumentation";
import { DATA_CONVENTION } from "./DocumentDataConvention";
import CustomTableWithPagination from "./CsvToTable";

const ReadmeRenderer = () => {
  const [selectedTab, setSelectedTab] = useState("API_Overview");
  const [selectedLanguage, setSelectedLanguage] = useState("curl");

  const languageOptions = ["curl", "javascript", "java", "python", "c#"];

  const copyToClipboard = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => toast.success("Code copied to clipboard!"))
      .catch(() => toast.error("Failed to copy to clipboard."));
  };

  // Filter the API_DOCUMENTATION based on selected language
  const filteredDocumentation = API_DOCUMENTATION.filter((block) => {
    if (block.type === "code") {
      return !(
        block.language &&
        languageOptions.includes(block.language.toLowerCase()) &&
        block.language.toLowerCase() !== selectedLanguage.toLowerCase()
      );
    }
    return true;
  });

  return (
    <>
      <div className="tabs">
        {[
          "API_Overview",
          "API_Documentation",
          "API_Payload",
          "Data_Convention",
          "Country_Parameters",
        ].map((tab) => (
          <button
            key={tab}
            className={`tab-button ${selectedTab === tab ? "active-tab" : ""}`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab.replace("_", " ")}
          </button>
        ))}
      </div>

      <div className="markdown-container">
        {selectedTab === "Country_Parameters" ? (
          <CustomTableWithPagination />
        ) : (
          (selectedTab === "API_Payload"
            ? API_PAYLOAD
            : selectedTab === "API_Overview"
            ? API_OVERVIEW
            : selectedTab === "Data_Convention"
            ? DATA_CONVENTION
            : selectedTab === "API_Documentation"
            ? filteredDocumentation
            : []
          ).map((block, index) => {
            if (typeof block === "string") {
              // Regular text content (render as HTML)
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: marked(block) }}
                />
              );
            } else if (block.type === "code") {
              return (
                <div className="code-card" key={index}>
                  <div className="code-header">
                    {/* Copy Button */}
                    <button
                      className="copy-btn"
                      onClick={() => copyToClipboard(block.code)}
                    >
                      <ContentCopyIcon />
                    </button>

                    {/* Language Dropdown */}
                    {languageOptions.includes(block.language.toLowerCase()) && (
                      <select
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        className="language-dropdown"
                      >
                        {languageOptions.map((lang) => (
                          <option key={lang} value={lang}>
                            {lang.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <CodeBlockBlack block={block} />
                </div>
              );
            }
            return null;
          })
        )}
      </div>
    </>
  );
};

export default ReadmeRenderer;
