import "./loginPassword.scss";
import React from "react";
import EximpediaLogo from "../../Asset/images/logo-dark-og.png";
import { Link } from "react-router-dom";
import AuthFooter from "../AuthFooter/authFooter.js";
import { Form, Field } from "formik";
import { useEffect, useState } from "react";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import PasswordStrength from "../PasswordStrength/PasswordStrength";
const LoginPassword = (props) => {
  const [isVisible, setVisible] = useState(false);
  const [isAllTrue, setAllTrue] = useState(false);
  const [passwordType, setPasswordType] = useState(props.type2);
  const [cpasswordType, setCpasswordType] = useState(props.type2);
  const togglePassword = () => {
    if (passwordType == props.type2) {
      setPasswordType("text");
      return;
    }
    setPasswordType(props.type2);
  };
  const toggleCpassword = () => {
    if (cpasswordType == props.type2) {
      setCpasswordType("text");
      return;
    }
    setCpasswordType(props.type2);
  };
  return (
    <Form>
      <div className="background-content mb-5" id="login-password">
        <div className="container">
          <div className="rowCards">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div
                style={{ marginTop: "50px" }}
                className="card-login bg-pattern"
              >
                <div className="card-body">
                  <div className="text-center w-75 m-auto">
                    <a href="/consumers/login" rel="noopener noreferrer">
                      <span>
                        <img
                          className="img-center"
                          src={EximpediaLogo}
                          alt=""
                          height="44"
                        />
                      </span>
                    </a>
                    {props?.isFromLogin && (
                      <div className="login-type-btn-container">
                        <span
                          className={`login-type-btn ${
                            !props?.isTradeUser ? "active-btn" : ""
                          } `}
                          onClick={() => {
                            props?.toggleTradeUserLogin(false);
                          }}
                        >
                          <Link
                            to="/login"
                            className={`login-btn-text ${
                              !props?.isTradeUser ? "active-text" : ""
                            }`}
                          >
                            Consumer
                          </Link>
                        </span>
                        <span
                          className={`login-type-btn ${
                            props?.isTradeUser ? "active-btn" : ""
                          }`}
                          onClick={() => {
                            props?.toggleTradeUserLogin(true);
                          }}
                        >
                          <Link
                            to="/trade-user/login"
                            className={`login-btn-text ${
                              props?.isTradeUser ? "active-text" : ""
                            }`}
                          >
                            Trade API
                          </Link>
                        </span>
                      </div>
                    )}
                    <p className="text-muted font-14 mb-4 mt-3">{props.text}</p>
                  </div>
                  <div className="form-group">
                    {props.type1 === props.type2 && (
                      <RemoveRedEyeRoundedIcon
                        style={{
                          marginTop: "42px",
                          marginLeft: "360px",
                          position: "absolute",
                          cursor: "pointer",
                          color: "#817979",
                        }}
                        onClick={togglePassword}
                      />
                    )}
                    {passwordType == "text" && (
                      <VisibilityOffRoundedIcon
                        style={{
                          marginTop: "42px",
                          marginLeft: "360px",
                          position: "absolute",
                          cursor: "pointer",
                          color: "#817979",
                        }}
                        onClick={togglePassword}
                      />
                    )}
                    <div>
                      <label className="font-14">{props.subject1}</label>
                      <Field
                        autocomplete="off"
                        type={
                          props.type1 === props.type2
                            ? passwordType
                            : props.type1
                        }
                        id="email-id-input"
                        name={props.name1}
                        className="form-control-login"
                        placeholder={props.subject1Placeholder}
                        onFocus={() => setVisible(true)}
                      />
                      {props.touched1 && props.errors1 && (
                        <div className="error-message">{props.errors1}</div>
                      )}
                    </div>
                  </div>
                  <div style={{ margin: " 20px 0px" }} className="form-group">
                    {cpasswordType == props.type2 ? (
                      <RemoveRedEyeRoundedIcon
                        style={{
                          marginTop: "42px",
                          marginLeft: "360px",
                          position: "absolute",
                          cursor: "pointer",
                          color: "#817979",
                        }}
                        onClick={toggleCpassword}
                      />
                    ) : (
                      <VisibilityOffRoundedIcon
                        style={{
                          marginTop: "42px",
                          marginLeft: "360px",
                          position: "absolute",
                          cursor: "pointer",
                          color: "#817979",
                        }}
                        onClick={toggleCpassword}
                      />
                    )}
                    <label className="font-14">{props.subject2}</label>
                    <Field
                      type={cpasswordType}
                      id="password-input"
                      name={props.name2}
                      className="form-control-login"
                      placeholder={props.subject2Placeholder}
                    />
                    {props.touched2 && props.errors2 && (
                      <div className="error-message">{props.errors2}</div>
                    )}
                    {props.isShowing && isVisible && (
                      <div className="password-strength-div">
                        <PasswordStrength
                          values={props.values}
                          setAllTrue={setAllTrue}
                          isAllTrue={isAllTrue}
                        />
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-0 text-center">
                    <button
                      type="submit"
                      id="cta-login-account"
                      className="button-color btn btn-primary waves-effect waves-light btn-block"
                    >
                      {props.buttonText}
                    </button>
                  </div>
                </div>
              </div>
              {props.login === "true" ? (
                <div className="mt-3">
                  <div className="col-12 text-center">
                    <p className="mt-5 mb-2 ">
                      <Link
                        to={`/password/recovery?isTradeUser=${
                          props?.isTradeUser ? "true" : "false"
                        }`}
                        rel="noopener noreferrer"
                        className="text-white-50 ml-1"
                      >
                        Forgot your password?
                      </Link>
                    </p>
                    {/* <p
                      className="text-white-50"
                      style={{ fontSize: "14px" }}
                      onClick={() => {
                        props?.toggleTradeUserLogin();
                      }}
                    >
                      Login in
                      <Link
                        to={props?.isTradeUser ? "/login" : "/trade-user/login"}
                        className="text-white ml-1"
                      >
                        <b>
                          {props?.isTradeUser
                            ? "Eximpedia Panel"
                            : "Trade API Panel"}
                        </b>
                      </Link>
                    </p> */}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <AuthFooter />
      </div>
    </Form>
  );
};
export default LoginPassword;
