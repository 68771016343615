// PieChart.js
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const MonthlyStatsPieChart = ({ data }) => {
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Requests by Month",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        animation: {
          duration: 1500, // Custom entrance animation duration
          easing: "easeOutBounce", // Animation easing
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        name: "Requests",
        colorByPoint: true,
        data: data?.map((item) => ({
          name: item?.month,
          y: item?.requests,
        })),
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default MonthlyStatsPieChart;
