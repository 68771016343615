import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid, Skeleton } from "@mui/material";
import "./TradePanelDashboard.scss";
import Error from "../../../../Component/ErrorLoader/error";
import { dashboardTradeApiClient } from "../../../../Services/service";
import { LogarithmicChart } from "../ApiConsumptionCharts/LogarithmicChart";
import { BasicColumnChart } from "../ApiConsumptionCharts/BasicColumnChart";
import MonthlyStatsBarChart from "../ApiConsumptionCharts/MonthlyStatsBarChart";
import MonthlyStatsPieChart from "../ApiConsumptionCharts/MonthlyStatsPieChart";
import TradeTypeVariableRadiusPieChart from "../ApiConsumptionCharts/TradeTypeVariableRadiusPieChart";
import TradeTypePieChart from "../ApiConsumptionCharts/TradeTypePieChart";
import CountryStatsBarChart from "../ApiConsumptionCharts/CountryStatsBarChart";
import CountryStatsColumnChart from "../ApiConsumptionCharts/CountryStatsColumnChart";
import { logger } from "../../../../Logger/Logger";

const TradePanelDashboard = () => {
  const [errorMsg, seterrorMsg] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dashboardValues, setDashboardValues] = useState({});

  const errorBlock = (errStatus) => {
    const errorForLogger = `TradeApi Dashboard page:- ${errStatus}`;
    logger(errorForLogger);
    setError(true);
    seterrorMsg({
      title:
        errStatus === 400 || errStatus === 401
          ? "Warning"
          : (errStatus === 500 || errStatus === 502) && "Error",
      content:
        errStatus === 400
          ? "Failed to get Profile Detail"
          : errStatus === 401
          ? "Session TimeOut , Please Login Again"
          : "Something Went Wrong , Please Login Again",
    });
  };

  const fetchTradeDashboard = () => {
    setLoading(true);
    dashboardTradeApiClient()
      .then((dashboardRes) => {
        if (dashboardRes?.data && dashboardRes?.status === 200) {
          setDashboardValues(dashboardRes?.data?.[0]);
        }

        if (
          dashboardRes?.status === 400 ||
          dashboardRes?.status === 401 ||
          dashboardRes?.status === 500
        ) {
          errorBlock(dashboardRes?.status);
        }
      })
      .catch((err) => {
        errorBlock(err?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTradeDashboard();
  }, []);

  return (
    <>
      {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
      <Grid container spacing={2} className="trade-dashboard-cards">
        <Grid item xs={12} md={6} lg={3}>
          <Card className="trade-card">
            <CardContent>
              <Typography className="trade-title">Credits This Year</Typography>
              <Typography className="trade-value">
                {loading ? (
                  <Skeleton width="100px" />
                ) : (
                  dashboardValues?.thisYearCredits?.[0]?.totalCreditsUtilized ??
                  0
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Card className="trade-card">
            <CardContent>
              <Typography className="trade-title">
                Credits Current Month
              </Typography>
              <Typography className="trade-value">
                {loading ? (
                  <Skeleton width="100px" />
                ) : (
                  dashboardValues?.thisMonthCredits?.[0]?.thisMonthCredits ?? 0
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Card className="trade-card">
            <CardContent>
              <Typography className="trade-title">
                Credits last 24 hours
              </Typography>
              <Typography className="trade-value">
                {loading ? (
                  <Skeleton width="100px" />
                ) : (
                  dashboardValues?.last24HoursStats?.[0]?.last24hoursCredits ??
                  0
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className="trade-card">
            <CardContent>
              <Typography className="trade-title">Daily Stats</Typography>
            </CardContent>
            <Grid container spacing={1} className="graph-container">
              <Grid xs={12} md={12} lg={5.5}>
                {loading ? (
                  <Skeleton variant="rectangular" height={300} />
                ) : (
                  <LogarithmicChart data={dashboardValues?.dailyStats} />
                )}
              </Grid>
              <Grid xs={12} md={12} lg={5.5}>
                {loading ? (
                  <Skeleton variant="rectangular" height={300} />
                ) : (
                  <BasicColumnChart data={dashboardValues?.dailyStats} />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className="trade-card">
            <CardContent>
              <Typography className="trade-title">Monthly Stats</Typography>
            </CardContent>
            <Grid container spacing={1} className="graph-container">
              <Grid xs={12} md={12} lg={5.5}>
                {loading ? (
                  <Skeleton variant="rectangular" height={300} />
                ) : (
                  <MonthlyStatsBarChart data={dashboardValues?.monthlyStats} />
                )}
              </Grid>
              <Grid xs={12} md={12} lg={5.5}>
                {loading ? (
                  <Skeleton variant="rectangular" height={300} />
                ) : (
                  <MonthlyStatsPieChart data={dashboardValues?.monthlyStats} />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className="trade-card">
            <CardContent>
              <Typography className="trade-title">
                Trade Type based Stats
              </Typography>
            </CardContent>
            <Grid container spacing={1} className="graph-container">
              <Grid xs={12} md={12} lg={5.5}>
                {loading ? (
                  <Skeleton variant="rectangular" height={300} />
                ) : (
                  <TradeTypeVariableRadiusPieChart
                    data={dashboardValues?.tradeTypeStats}
                  />
                )}
              </Grid>
              <Grid xs={12} md={12} lg={5.5}>
                {loading ? (
                  <Skeleton variant="rectangular" height={300} />
                ) : (
                  <TradeTypePieChart data={dashboardValues?.tradeTypeStats} />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className="trade-card">
            <CardContent>
              <Typography className="trade-title">Country Stats</Typography>
            </CardContent>
            <Grid container spacing={1} className="graph-container">
              <Grid xs={12} md={12} lg={5.5}>
                {loading ? (
                  <Skeleton variant="rectangular" height={300} />
                ) : (
                  <CountryStatsBarChart data={dashboardValues?.countryStats} />
                )}
              </Grid>
              <Grid xs={12} md={12} lg={5.5}>
                {loading ? (
                  <Skeleton variant="rectangular" height={300} />
                ) : (
                  <CountryStatsColumnChart
                    data={dashboardValues?.countryStats}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default TradePanelDashboard;
