import React from "react";
import TradeUserSideBar from "../../Component/TradeUserSideBar/TradeUserSideBar";

function TradeAPIContainer({ children }) {
  return (
    <>
      <TradeUserSideBar>{children}</TradeUserSideBar>
    </>
  );
}

export default TradeAPIContainer;
