import * as React from "react";
import {
  Table,
  TableCell,
  TableRow,
  Box,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import "./exploreModal.scss";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import GoogleImage from "../../Asset/images/google-logo.png";
import moment from "moment";
import {
  ADDRESS,
  KEY_EXPORTER,
  KEY_IMPORTER,
  TradeType,
  modalheaderArrCompanyDetailsData_Loop,
  modalheaderArr_companyDetailsData,
  modalheaderArr_productDetailsData,
  COMPANY_DETAIL_VALUE_FOR_HIDE_CONTACT_ICON,
} from "./ExploreModal.enum";
import { translateButtonClick } from "../../utils/CommanFunction";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

const FilterButton = styled(Button)({
  color: "#fff",
  backgroundColor: "#005D91",
  borderColor: " #005D91",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#07527D !important",
    borderColor: "#07527D !important",
  },
  ":disabled": {
    color: "white",
    backgroundColor: "#62acd5 !important",
  },
});

const ExploreDataTableModal = (props) => {
  let RowIsFavCompany, RowIsFavShipment;

  if (props?.Favourite) {
    RowIsFavCompany =
      props?.modalheaderArr &&
      props?.modalheaderArr.length > 0 &&
      props?.modalheaderArr.filter((e) => Object.keys(e)[0] === "isFavourite");
    RowIsFavShipment =
      props?.modalheaderArr &&
      props?.modalheaderArr.length > 0 &&
      props?.modalheaderArr.filter(
        (e) => Object.keys(e)[0] === "isFavouriteShipment"
      );
  }

  const [companyDetails, setCompanyDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [otherDetails, setOtherDetails] = useState([]);
  const [IECDetails, setIECDetails] = useState([]);
  const [consigneeDetails, setConsigneeDetails] = useState([]);
  const [favColumn, setFavColumn] = useState("");
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const _companyDetailsData =
      props?.modalheaderArr &&
      props?.modalheaderArr.length > 0 &&
      props?.modalheaderArr.filter((e) => {
        return modalheaderArr_companyDetailsData.includes(Object.keys(e)[0]);
      });
    if (props?.Favourite) {
      const intersection =
        _companyDetailsData &&
        _companyDetailsData.length > 0 &&
        _companyDetailsData.find((e) => {
          return (
            props?.searchOption &&
            props?.searchOption &&
            props?.searchOption.find((element) => {
              let tempCmp = "";
              if (
                props?.currentCountry === "INDIA" &&
                props?.currentTradeType === TradeType?.IMPORT
              ) {
                tempCmp = "IMPORTER NAME";
              } else if (
                props?.currentCountry === "INDIA" &&
                props?.currentTradeType === TradeType?.EXPORT
              ) {
                tempCmp = "EXPORTER NAME";
              } else {
                tempCmp = Object.keys(e)[0];
              }
              return tempCmp === element?.field?.split("_").join(" ");
            })
          );
        });

      if (intersection && Object.keys(intersection).length) {
        setFavColumn(Object.keys(intersection)[0]);
      }
    }

    let companyDetailsData = [];
    props?.modalheaderArr &&
      props?.modalheaderArr.length > 0 &&
      props?.modalheaderArr.forEach((e) => {
        if (modalheaderArrCompanyDetailsData_Loop.includes(Object.keys(e)[0])) {
          const currentComming = Object.keys(e)[0].split(" ")[0];
          let add;
          let name;
          if (props?.currentTradeType?.toUpperCase() === TradeType?.IMPORT) {
            name = KEY_IMPORTER?.IMPORTER;
            // && Object.keys(e)[0] !== "IMPORTER NAME"
            if (Object.keys(e)[0] !== KEY_IMPORTER?.IMPORTER) {
              add = currentComming + " " + ADDRESS;
            } else {
              add = ADDRESS;
            }
          }

          if (props?.currentTradeType === TradeType?.EXPORT) {
            name = KEY_EXPORTER?.EXPORTER;
            //  && Object.keys(e)[0] !== "EXPORTER NAME"
            if (Object.keys(e)[0] !== KEY_EXPORTER?.EXPORTER) {
              add = currentComming + " " + ADDRESS;
            } else {
              add = ADDRESS;
            }
          }

          if (Object.keys(e)[0] === `${name} NAME EN`) {
            add = `${name} ADDRESS EN`;
          } else if (Object.keys(e)[0] === `${name} NAME VN`) {
            add = `${name} ADDRESS VN`;
          } else if (Object.keys(e)[0] === "NOTIFY PARTY NAME") {
            add = "NOTIFY PARTY ADDRESS";
          }
          const obj = {
            [add]: "NULL",
          };
          companyDetailsData.push([e, obj]);
        }
      });

    props?.modalheaderArr &&
      props?.modalheaderArr.length > 0 &&
      props?.modalheaderArr.forEach((e) => {
        let name;
        if (
          props?.currentTradeType === TradeType?.IMPORT &&
          props?.currentCountry === "INDIA"
        ) {
          if (Object.keys(e)[0] === ADDRESS) {
            setCompanyDetailsHeader(
              companyDetailsData,
              ADDRESS,
              Object.values(e)[0]
            );
          } else if (Object.keys(e)[0] === KEY_IMPORTER?.SUPPLIER_ADDRESS) {
            setCompanyDetailsHeader(
              companyDetailsData,
              KEY_IMPORTER?.SUPPLIER_ADDRESS,
              Object.values(e)[0]
            );
          }
        } else if (
          props?.currentTradeType === TradeType?.EXPORT &&
          props?.currentCountry === "INDIA"
        ) {
          if (Object.keys(e)[0] === ADDRESS) {
            setCompanyDetailsHeader(
              companyDetailsData,
              ADDRESS,
              Object.values(e)[0]
            );
          } else if (Object.keys(e)[0] === KEY_IMPORTER?.CONSIGNEE_ADDRESS) {
            setCompanyDetailsHeader(
              companyDetailsData,
              KEY_IMPORTER?.CONSIGNEE_ADDRESS,
              Object.values(e)[0]
            );
          }
        } else {
          if (props?.currentTradeType === TradeType?.IMPORT) {
            name = KEY_IMPORTER?.IMPORTER;
            if (Object.keys(e)[0] === KEY_IMPORTER?.IMPORTER_ADDRESS) {
              setCompanyDetailsHeader(
                companyDetailsData,
                KEY_IMPORTER?.IMPORTER_ADDRESS,
                Object.values(e)[0]
              );
            } else if (Object.keys(e)[0] === KEY_IMPORTER?.SUPPLIER_ADDRESS) {
              setCompanyDetailsHeader(
                companyDetailsData,
                KEY_IMPORTER?.SUPPLIER_ADDRESS,
                Object.values(e)[0]
              );
            } else if (Object.keys(e)[0] === KEY_IMPORTER?.SHIPPER_ADDRESS) {
              setCompanyDetailsHeader(
                companyDetailsData,
                KEY_IMPORTER?.SHIPPER_ADDRESS,
                Object.values(e)[0]
              );
            } else if (Object.keys(e)[0] === KEY_IMPORTER?.CONSIGNEE_ADDRESS) {
              setCompanyDetailsHeader(
                companyDetailsData,
                KEY_IMPORTER?.CONSIGNEE_ADDRESS,
                Object.values(e)[0]
              );
            } else if (
              Object.keys(e)[0] === KEY_IMPORTER?.NOTIFY_PARTY_ADDRESS
            ) {
              setCompanyDetailsHeader(
                companyDetailsData,
                KEY_IMPORTER?.NOTIFY_PARTY_ADDRESS,
                Object.values(e)[0]
              );
            }
          } else {
            name = KEY_EXPORTER?.EXPORTER;
            if (Object.keys(e)[0] === KEY_EXPORTER?.BUYER_ADDRESS) {
              setCompanyDetailsHeader(
                companyDetailsData,
                KEY_EXPORTER?.BUYER_ADDRESS,
                Object.values(e)[0]
              );
            } else if (Object.keys(e)[0] === KEY_EXPORTER?.EXPORTER_ADDRESS) {
              setCompanyDetailsHeader(
                companyDetailsData,
                KEY_EXPORTER?.EXPORTER_ADDRESS,
                Object.values(e)[0]
              );
            } else if (Object.keys(e)[0] === KEY_EXPORTER?.SHIPPER_ADDRESS) {
              setCompanyDetailsHeader(
                companyDetailsData,
                KEY_EXPORTER?.SHIPPER_ADDRESS,
                Object.values(e)[0]
              );
            }
          }

          if (Object.keys(e)[0] === `${name} ADDRESS EN`) {
            setCompanyDetailsHeader(
              companyDetailsData,
              `${name} ADDRESS EN`,
              Object.values(e)[0]
            );
          } else if (Object.keys(e)[0] === `${name} ADDRESS VN`) {
            setCompanyDetailsHeader(
              companyDetailsData,
              `${name} ADDRESS VN`,
              Object.values(e)[0]
            );
          }
        }
      });
    const productDetailsData =
      props?.modalheaderArr &&
      props?.modalheaderArr.length > 0 &&
      props?.modalheaderArr.filter((e) => {
        return modalheaderArr_productDetailsData?.includes(Object.keys(e)[0]);
      });

    let otherDetailsData = [];
    props?.modalheaderArr &&
      props?.modalheaderArr.length > 0 &&
      props?.modalheaderArr.forEach((e) => {
        if (
          !_companyDetailsData.includes(e) &&
          !productDetailsData.includes(e)
        ) {
          if (
            Object.values(e)[0] !== undefined &&
            Object.keys(e)[0] !== "isFavourite" &&
            Object.keys(e)[0] !== "isFavouriteShipment"
          ) {
            otherDetailsData.push(e);
          }
        }
      });

    let pairArrOfOtherDetails = [];

    if (otherDetailsData?.length % 2 === 0) {
      for (let i = 2; i <= otherDetailsData?.length; i += 2) {
        let arr = otherDetailsData.slice(i - 2, i);
        pairArrOfOtherDetails.push([arr[0], arr[1]]);
      }
    } else {
      for (let i = 2; i < otherDetailsData?.length; i += 2) {
        let arr = otherDetailsData.slice(i - 2, i);
        pairArrOfOtherDetails.push([arr[0], arr[1]]);
      }
      pairArrOfOtherDetails.push([
        otherDetailsData[otherDetailsData.length - 1],
      ]);
    }

    let pairArrOfIECDetails = [];

    if (props?.IECDetails?.length > 0) {
      if (Object.keys(props?.IECDetails[0]).length % 2 === 0) {
        for (
          let i = 2;
          i <= Object.keys(props?.IECDetails[0]).length - 1;
          i += 2
        ) {
          let arr = Object.keys(props?.IECDetails[0]).slice(i - 2, i);
          if (
            arr[0] !== "_id" &&
            arr[0] !== "Banker_Detail" &&
            arr[1] !== "_id" &&
            arr[1] !== "Banker_Detail"
          ) {
            pairArrOfIECDetails.push([arr[0], arr[1]]);
          }
        }
      } else {
        for (let i = 2; i < Object.keys(props?.IECDetails[0]).length; i += 2) {
          let arr = Object.keys(props?.IECDetails[0]).slice(i - 2, i);
          let first, second;

          if (
            arr[0] !== "_id" &&
            arr[0] !== "Banker_Detail" &&
            arr[1] !== "_id" &&
            arr[1] !== "Banker_Detail"
          ) {
            first = arr[0];
            second = arr[1];
            let pairArr = [
              {
                [first.split("_").join(" ").toUpperCase()]:
                  props?.IECDetails[0][first]
                    .split("_")
                    .join(" ")
                    .toUpperCase(),
              },
              {
                [second.split("_").join(" ").toUpperCase()]:
                  props?.IECDetails[0][second]
                    .split("_")
                    .join(" ")
                    .toUpperCase(),
              },
            ];
            pairArrOfIECDetails.push(pairArr);
          }
        }
      }
    }

    setCompanyDetails(companyDetailsData);
    setProductDetails(productDetailsData);
    setOtherDetails(pairArrOfOtherDetails);
    setIECDetails(pairArrOfIECDetails);
  }, [props?.modalheaderArr]);

  useEffect(() => {
    let consigneeDetailsArr = [];
    if (props?.consigneeDetails?.data !== undefined) {
      if (props?.consigneeDetails?.data.hasOwnProperty("_id")) {
        delete props?.consigneeDetails?.data?._id;
      }

      if (props?.consigneeDetails?.data.hasOwnProperty("shipment_number")) {
        delete props?.consigneeDetails?.data?.shipment_number;
      }

      if (props?.consigneeDetails?.data.hasOwnProperty("created_at")) {
        props.consigneeDetails.data.created_at = moment(
          Number(props?.consigneeDetails.data.created_at)
        ).format("DD-MM-YYYY");
      }
      Object.entries(props?.consigneeDetails?.data).forEach((e) => {
        if (e[1] === "") {
          props.consigneeDetails.data[e[0]] = "NULL";
        }
      });

      if (Object.keys(props?.consigneeDetails.data).length % 2 === 0) {
        for (
          let i = 2;
          i <= Object.keys(props?.consigneeDetails.data).length;
          i += 2
        ) {
          let arr = Object.entries(props?.consigneeDetails.data).slice(
            i - 2,
            i
          );
          let obj;
          obj = [
            { [arr[0][0].split("_").join(" ").toUpperCase()]: arr[0][1] },
            { [arr[1][0].split("_").join(" ").toUpperCase()]: arr[1][1] },
          ];

          consigneeDetailsArr.push(obj);
        }
      } else {
        for (
          let i = 2;
          i < Object.keys(props?.consigneeDetails.data).length;
          i += 2
        ) {
          let arr = Object.entries(props?.consigneeDetails.data).slice(
            i - 2,
            i
          );
          let obj;

          obj = [
            { [arr[0][0].split("_").join(" ").toUpperCase()]: arr[0][1] },
            { [arr[1][0].split("_").join(" ").toUpperCase()]: arr[1][1] },
          ];

          consigneeDetailsArr.push(obj);
        }
      }
    }
    setConsigneeDetails(consigneeDetailsArr);
  }, [props?.consigneeDetails]);

  const handleGoogleSearch = (searchTerm) => {
    var left = windowDimensions.width / 2 - 800 / 2;
    var top = windowDimensions.height / 2 - 500 / 2;
    window.open(
      `https://cse.google.com/cse?cx=11ba467c79bc5621d&q=${searchTerm}`,
      searchTerm,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        800 +
        ", height=" +
        500 +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };
  const setCompanyDetailsHeader = (
    companyDetailsData,
    headerTitle,
    headerValue
  ) => {
    companyDetailsData.forEach((elem) => {
      elem.forEach((el) => {
        if (Object.keys(el)[0] === headerTitle) {
          el[headerTitle] = headerValue;
        }
      });
    });
  };
  useEffect(() => {
    translateButtonClick();
  }, []);

  useEffect(() => {
    if (RowIsFavShipment?.length) {
      props?.setIsFavShipment(Object.values(RowIsFavShipment?.[0])?.[0]);
    }
  }, [RowIsFavShipment]);
  return (
    <Box id="exploreModal">
      <Box
        id="printContent"
        ref={props?.PrintRef}
        style={{
          overflow: "scroll",
        }}
      >
        {/* {props?.Favourite && Object.values(RowIsFavShipment[0])[0]
          ? props?.Favourite && (
              <Tooltip title={"Favourite Shipment"} arrow>
                <StarRoundedIcon
                  onClick={() => props?.handleFavouriteShipment()}
                  style={{
                    // position: "absolute",
                    // top: "13px",
                    // left: "120px",
                    cursor: "pointer",
                    fontSize: "2rem",
                  }}
                />
              </Tooltip>
            )
          : // <IconButton>
            props?.Favourite && (
              <Tooltip title={"Favourite Shipment"} arrow>
                <StarBorderRoundedIcon
                  onClick={() => props?.handleFavouriteShipment()}
                  style={{
                    // position: "absolute",
                    // top: "13px",
                    // left: "120px",
                    cursor: "pointer",
                    fontSize: "2rem",
                  }}
                />
              </Tooltip>
            )} */}

        <TableRow
          id="companyDetails"
          className="details"
          onClick={(e) => props?.handleCollapseDetails(e)}
          style={{ marginTop: "0px" }}
        >
          Company Details
          {props?.isCollapseModelDetails?.companyDetails ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </TableRow>

        {props?.isCollapseModelDetails?.companyDetails && (
          <Table>
            {companyDetails &&
              companyDetails.length > 0 &&
              companyDetails.map((e) => {
                return (
                  <>
                    <TableRow>
                      <TableCell className="cmp-table-head set-border">
                        {
                          // Object.keys(e[0])[0] === "IMPORTER" || Object.keys(e[0])[0] === "EXPORTER"
                          // Object.keys(companyDetails[0][0])[0]
                          favColumn === Object.keys(e[0])[0]
                            ? props?.Favourite &&
                              Object.values(RowIsFavCompany[0])[0]
                              ? props?.Favourite && (
                                  <CustomTooltip
                                    title={"Favourite Company"}
                                    left="142%"
                                    arrowPosition="23%"
                                  >
                                    <StarRoundedIcon
                                      onClick={() =>
                                        props?.handleFavouriteCompany(
                                          Object.values(e[0])[0],
                                          Object.keys(e[0])[0]
                                        )
                                      }
                                      style={{
                                        margin: "0px 3px",
                                        cursor: "pointer",
                                        fontSize: "2rem",
                                      }}
                                    />
                                  </CustomTooltip>
                                )
                              : props?.Favourite && (
                                  <CustomTooltip
                                    title={"Favourite Company"}
                                    left="142%"
                                    arrowPosition="23%"
                                  >
                                    <StarBorderRoundedIcon
                                      onClick={() =>
                                        props?.handleFavouriteCompany(
                                          Object.values(e[0])[0],
                                          Object.keys(e[0])[0]
                                        )
                                      }
                                      style={{
                                        margin: "0px 3px",
                                        cursor: "pointer",
                                        fontSize: "2rem",
                                      }}
                                    />
                                  </CustomTooltip>
                                )
                            : ""
                        }
                      </TableCell>
                      <TableCell className="cmp-table-head set-border">
                        {Object.keys(e[0])[0]}
                      </TableCell>

                      <TableCell className="set-border">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <div>{Object.values(e[0])[0]}</div>
                          {!Object.values(e[0]).some((value) =>
                            COMPANY_DETAIL_VALUE_FOR_HIDE_CONTACT_ICON.some(
                              (target) => value.includes(target)
                            )
                          ) && (
                            <div>
                              <img
                                src={GoogleImage}
                                alt="Google Icon"
                                width="20px"
                                height="20px"
                                style={{
                                  float: "right",
                                  cursor: "pointer",
                                  margin: "0px 3px",
                                }}
                                onClick={() =>
                                  handleGoogleSearch(Object.values(e[0])[0])
                                }
                                id="googleIcon"
                              />
                            </div>
                          )}
                        </div>
                      </TableCell>

                      <TableCell className="cmp-table-head set-border">
                        {Object.keys(e[1])[0]}
                      </TableCell>

                      <TableCell className="set-border">
                        <div
                          style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ margin: "0px", padding: "0px" }}>
                            {Object.values(e[1])[0]}
                          </div>
                          {!Object.values(e[0]).some((value) =>
                            COMPANY_DETAIL_VALUE_FOR_HIDE_CONTACT_ICON.some(
                              (target) => value.includes(target)
                            )
                          ) && (
                            <div
                              style={{
                                cursor: "pointer",
                                color: "#303336",
                                margin: "1px",
                                fontSize: "10px",
                              }}
                              onClick={() => {
                                props?.CompanyDetailOnContactClick(
                                  Object.keys(e[0]),
                                  Object.values(e[0])[0]
                                );
                              }}
                            >
                              <CustomTooltip title={"Contact"} arrow>
                                <ContactMailIcon
                                  style={{ fontSize: "32px" }}
                                  className="contact-icon"
                                />
                              </CustomTooltip>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </Table>
        )}
        {props?.currentCountry === "INDIA" &&
          props?.currentTradeType === TradeType?.EXPORT && (
            <Box style={{ marginTop: "20px" }}>
              {props?.consigneeDetails?.message ? (
                <Box style={{ display: "flex" }}>
                  <FilterButton
                    onClick={() => props?.handleAddConsigneeRequest()}
                    disabled={props?.consigneeDetails.disabled}
                  >
                    {props?.consigneeDetails.message}
                  </FilterButton>
                  <div style={{ margin: "auto 21px" }}>
                    <small className="text-muted">
                      You have consumed{" "}
                      <strong className="notranslate" style={{ color: "red" }}>
                        {props?.consigneeDetails?.shipmentConsumedLimits}
                      </strong>{" "}
                      and left with&nbsp;
                      <strong
                        className="notranslate"
                        style={{ color: "green" }}
                      >
                        {props?.consigneeDetails?.shipmentAllotedLimits -
                          props?.consigneeDetails?.shipmentConsumedLimits}
                      </strong>
                      &nbsp;limit for buyer request
                    </small>
                  </div>
                </Box>
              ) : (
                <>
                  {props?.consigneeDetails && (
                    <TableRow
                      id="consigneeDetails"
                      className="details"
                      onClick={(e) => props?.handleCollapseDetails(e)}
                    >
                      Consignee Details
                      {props?.isCollapseModelDetails?.consigneeDetails ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </TableRow>
                  )}
                  {props?.isCollapseModelDetails?.consigneeDetails && (
                    <Table>
                      {consigneeDetails &&
                        consigneeDetails.length > 0 &&
                        consigneeDetails.map((data) => {
                          return (
                            <TableRow>
                              {data.map((e) => {
                                return (
                                  <>
                                    <TableCell className="table-head set-border">
                                      {Object.keys(e)[0]}
                                    </TableCell>
                                    <TableCell className="set-border">
                                      {Object.values(e)[0]}
                                    </TableCell>
                                  </>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </Table>
                  )}
                </>
              )}
            </Box>
          )}

        <TableRow
          id="productDetails"
          className="details"
          onClick={(e) => props?.handleCollapseDetails(e)}
        >
          Product Details
          {props?.isCollapseModelDetails?.productDetails ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </TableRow>
        {props?.isCollapseModelDetails?.productDetails && (
          <Table>
            {productDetails.length > 0 &&
              productDetails.map((e) => {
                return (
                  <>
                    <TableRow>
                      <TableCell className="table-head set-border">
                        {Object.keys(e)[0]}
                      </TableCell>
                      <TableCell className="set-border">
                        {Object.values(e)[0]}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </Table>
        )}

        <TableRow
          id="otherDetails"
          className="details"
          onClick={(e) => props?.handleCollapseDetails(e)}
          name=""
        >
          Details
          {props?.isCollapseModelDetails.otherDetails ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </TableRow>
        {props?.isCollapseModelDetails.otherDetails && (
          <Table>
            {otherDetails.length > 0 &&
              otherDetails.map((data) => {
                return (
                  <TableRow>
                    {data &&
                      data.length &&
                      data.map((e) => {
                        if (Object.keys(e) !== "isFavourite") {
                          return (
                            <>
                              <TableCell className="table-head set-border">
                                {Object.keys(e)[0]}
                              </TableCell>
                              <TableCell className="set-border">
                                {Object.values(e)[0]}
                              </TableCell>
                            </>
                          );
                        }
                      })}
                  </TableRow>
                );
              })}
          </Table>
        )}

        {props?.currentCountry === "INDIA" && IECDetails.length > 0 && (
          <TableRow
            id="IECDetails"
            className="details"
            onClick={(e) => props?.handleCollapseDetails(e)}
            name=""
          >
            IEC Details
            {props?.isCollapseModelDetails?.IECDetails ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </TableRow>
        )}
        {props?.isCollapseModelDetails?.IECDetails && (
          <Table>
            {IECDetails.length > 0 &&
              IECDetails.map((data) => {
                return (
                  <TableRow>
                    {data &&
                      data.length &&
                      data.map((e, i) => {
                        return (
                          <>
                            <TableCell className="table-head set-border">
                              {e.toUpperCase().split("_").join(" ")}
                            </TableCell>
                            <TableCell className="set-border">
                              {props?.IECDetails[0][e]}
                            </TableCell>
                          </>
                        );
                      })}
                  </TableRow>
                );
              })}
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default ExploreDataTableModal;
