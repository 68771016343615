import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import "./ProfileCards.scss";
import { tradeUserProfile } from "../../../../Services/service";
import Error from "../../../../Component/ErrorLoader/error";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { logger } from "../../../../Logger/Logger";

const defaultProfileData = {
  name: "",
  email: "",
  phone: "",
  company: "",
  plan_constraints: {
    subscription_type: "",
    access_validity_interval: "",
    data_availability_interval: "",
    countries_available: [],
    credit_points: { total_remaining_credits: "" },
  },
};

const ProfileCards = () => {
  const [profileDetail, setProfileDetail] = useState(defaultProfileData);
  const [errorMsg, seterrorMsg] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    name,
    email,
    phone,
    company,
    plan_constraints: {
      subscription_type,
      access_validity_interval,
      data_availability_interval,
      countries_available,
      credit_points: { total_remaining_credits },
    },
  } = profileDetail;

  const errorBlock = (errStatus) => {
    const errorForLogger = `TradeApi Profile page:- ${errStatus}`;
    logger(errorForLogger);
    setError(true);
    seterrorMsg({
      title:
        errStatus === 400 || errStatus === 401
          ? "Warning"
          : (errStatus === 500 || errStatus === 502) && "Error",
      content:
        errStatus === 400
          ? "Failed to get Profile Detail"
          : errStatus === 401
          ? "Session TimeOut , Please Login Again"
          : "Something Went Wrong , Please Login Again",
    });
  };

  const fetchTradeUserDetail = () => {
    setLoading(true);
    tradeUserProfile()
      .then((profileRes) => {
        if (profileRes?.data && profileRes?.status === 200) {
          setProfileDetail(profileRes?.data);
        }

        if (
          profileRes?.status === 400 ||
          profileRes?.status === 401 ||
          profileRes?.status === 500
        ) {
          errorBlock(profileRes?.status, profileDetail?.data?.data?.type);
        }
      })
      .catch((err) => {
        errorBlock(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTradeUserDetail();
  }, []);

  return (
    <>
      {loading && (
        <Stack
          sx={{ color: "grey.500" }}
          spacing={2}
          direction="row"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          className="trade-panel-loader"
        >
          <CircularProgress color="inherit" />
        </Stack>
      )}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
      <Grid container spacing={3} className="profiler-cards">
        <Grid item xs={12} md={12} lg={4.5}>
          <Card className="card equal-height">
            <CardContent>
              <Typography className="title">Name</Typography>
              <Typography className="value">{name ?? " "}</Typography>
            </CardContent>

            <CardContent>
              <Typography className="title">Email</Typography>
              <Typography className="value">{email ?? " "}</Typography>
            </CardContent>

            <CardContent>
              <Typography className="title">Phone</Typography>
              <Typography className="value">{phone ?? " "}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={7}>
          <Card className="card equal-height">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <CardContent>
                  <Typography className="title">Subscription</Typography>
                  <Typography className="value">
                    {subscription_type ?? ""}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardContent>
                  <Typography className="title">Remaining Credits</Typography>
                  <Typography
                    className="value"
                    sx={{ color: "#004D7A !important" }}
                  >
                    {total_remaining_credits ?? 0}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardContent>
                  <Typography className="title">Data Availability</Typography>
                  <Typography className="detail">{`Start: ${new Date(
                    data_availability_interval?.start_date
                  ).toLocaleDateString()}`}</Typography>
                  <Typography className="detail">{`End: ${new Date(
                    data_availability_interval?.end_date
                  ).toLocaleDateString()}`}</Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <CardContent>
                  <Typography className="title">Access Validity</Typography>
                  <Typography className="detail">{`Start: ${new Date(
                    access_validity_interval?.start_date
                  ).toLocaleDateString()}`}</Typography>
                  <Typography className="detail">{`End: ${new Date(
                    access_validity_interval?.end_date
                  ).toLocaleDateString()}`}</Typography>
                </CardContent>
              </Grid>
            </Grid>

            <CardContent>
              <Typography className="title">Company</Typography>
              <Typography className="value">{company?.name}</Typography>
              <Typography className="detail">{company?.industry}</Typography>
              <Typography className="detail">{`${company?.city}, ${company?.state}, ${company?.country}`}</Typography>
              <Typography className="detail">{company?.website}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Card className="card equal-height">
            <CardContent>
              <Typography className="title">Countries Available</Typography>
              <div className="countries-available">
                {countries_available?.custom?.length &&
                  countries_available?.custom?.map((country, index) => (
                    <span key={index} className="country">
                      {country}
                    </span>
                  ))}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileCards;
