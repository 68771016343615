import React, { useState, useRef, useEffect } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PublicIcon from "@mui/icons-material/Public";
import LaunchIcon from "@mui/icons-material/Launch";
import BoltIcon from "@mui/icons-material/Bolt";
import ApiIcon from "@mui/icons-material/Api";
import "./AllAppsButton.scss";
import { useAppContext } from "../../Contexts/AppContext";
import { logOutAction } from "../../Containers/Logout/logout";
import { useNavigate } from "react-router";
import { USER_TYPE } from "../../enums/userEnum";

const AllAppsButton = () => {
  const { userType } = useAppContext();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setTimeout(() => {
          setIsOpen(false);
        }, 0);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClickAction = () => {
    logOutAction();
    setTimeout(() => {
      if (userType === USER_TYPE.API_USER) {
        navigate("/login");
      } else {
        navigate("/trade-user/login");
      }
    }, [200]);
  };

  return (
    <div className="all-apps-container" ref={popupRef}>
      <button
        className="all-apps-button"
        onClick={togglePopup}
        aria-expanded={isOpen}
      >
        <AppsIcon className="all-apps-icon" />
        All Apps
      </button>

      {isOpen && (
        <>
          <div className="popup-list" tabIndex={-1}>
            <ul>
              <li
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <PublicIcon className="item-icon" />
                <div className="item-content">
                  <div>
                    <h4>Trade Panel</h4>
                    <p>
                      Access country trade data, mirror and global searches,
                      market trends, and product insights
                    </p>
                  </div>
                  {userType === USER_TYPE.API_USER && (
                    <div
                      className="action-container"
                      onClick={handleClickAction}
                    >
                      <LaunchIcon sx={{ fontSize: "20px" }} />
                    </div>
                  )}
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <ApiIcon className="item-icon" />
                <div className="item-content">
                  <div>
                    <h4>Trade API Panel</h4>
                    <p>
                      API services for seamless trade integration. Manage keys,
                      access documentation, and monitor usage
                    </p>
                  </div>
                  {userType !== USER_TYPE.API_USER && (
                    <div
                      className="action-container"
                      onClick={handleClickAction}
                    >
                      <LaunchIcon sx={{ fontSize: "20px" }} />
                    </div>
                  )}
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <LocalShippingIcon className="item-icon" />
                <div className="item-content">
                  <div>
                    <h4>
                      Logistics Panel
                      <span className="coming-soon-badge">Coming Soon</span>
                    </h4>
                    <p>
                      Manage shipments, optimize supply chains, and streamline
                      logistics operations
                    </p>
                  </div>
                  <div className="action-container">
                    <BoltIcon sx={{ color: "#ef904a" }} />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default AllAppsButton;
