import React, { memo } from "react";
import Button from "@mui/material/Button";
import "./Button.scss";
import { CircularProgress, Tooltip, Typography } from "@mui/material";
export const ButtonComponent = memo((props) => {
  return (
    <Button
      startIcon={
        props?.btnIcon ? (
          <props.btnIcon
            style={{
              width: "20px",
              height: "20px",
              margin: "0px 2px 0px 2px",
              ...props?.iconStyle,
            }}
          />
        ) : (
          ""
        )
      }
      className={
        props?.className
          ? `buttonComponent btn-border  btn-text ${props?.className}`
          : "buttonComponent btn-border btn-text"
      }
      style={{
        ...props?.style,
        opacity: props?.disabled ? 0.5 : 1,
      }}
      onClick={props?.btnClick}
      // size="small"
      disabled={props?.disabled}
      onMouseLeave={props?.onMouseLeave}
      onMouseOver={props?.btnOver}
      onMouseEnter={props?.onMouseEnter}
      endIcon={
        props?.endIcon ? (
          <props.endIcon
            style={{ width: "20px", height: "20px", margin: "0px 2px 0px 2px" }}
          />
        ) : (
          ""
        )
      }
    >
      {props?.isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Typography
          className={
            props?.typographyClassName
              ? `btn_typography ${props?.typographyClassName} `
              : "btn_typography "
          }
          gutterBottom
          title={props?.name?.toUpperCase()}
        >
          {props?.name?.toUpperCase()}
        </Typography>
      )}
    </Button>
  );
});

export const DeleteButton = memo((props) => {
  return (
    <Button
      startIcon={
        props?.btnIcon ? (
          <props.btnIcon
            style={{ width: "25px", height: "25px", ...props?.iconStyle }}
          />
        ) : (
          ""
        )
      }
      className={
        props?.className
          ? `${props?.className} btn-border btn-text`
          : "deleteButton btn-border btn-text"
      }
      style={{ ...props?.style }}
      onClick={props?.btnClick}
    >
      {props?.name?.toUpperCase()}
    </Button>
  );
});

export const BtnGreen = memo((props) => {
  return (
    <Button
      startIcon={
        props?.btnIcon ? (
          <props.btnIcon
            style={{ width: "25px", height: "25px", margin: "0px 2px 0px 2px" }}
          />
        ) : (
          ""
        )
      }
      className={
        props?.className
          ? `${props?.className} btn-border btn-text`
          : "btnGreen btn-border btn-text"
      }
      style={{ ...props?.style }}
      onClick={props?.btnClick}
    >
      {props?.name?.toUpperCase()}
    </Button>
  );
});
export const ButtonComponentWithToolTip = memo((props) => {
  return (
    <Tooltip
      title={
        <span className="tooltipTitle">
          {props?.tooltipTitle?.split("_").join("  ")}
        </span>
      }
      arrow
    >
      <Button
        startIcon={
          props?.btnIcon ? (
            <props.btnIcon
              style={{
                width: "20px",
                height: "20px",
                margin: "0px 2px 0px 2px",
                ...props?.iconStyle,
              }}
            />
          ) : (
            ""
          )
        }
        className={
          props?.className
            ? `buttonComponent btn-border  btn-text ${props?.className}`
            : "buttonComponent btn-border btn-text"
        }
        style={{ ...props?.style, opacity: props?.disabled ? 0.5 : 1 }}
        onClick={props?.btnClick}
        // size="small"
        disabled={props?.disabled}
        endIcon={
          props?.endIcon ? (
            <props.endIcon
              style={{
                width: "20px",
                height: "20px",
                margin: "0px 2px 0px 2px",
              }}
            />
          ) : (
            ""
          )
        }
      >
        <Typography
          className={
            props?.typographyClassName
              ? `btn_typography ${props?.typographyClassName} `
              : "btn_typography "
          }
        >
          {!props?.toUpperCase ? props?.name?.toUpperCase() : props?.name}
        </Typography>
      </Button>
    </Tooltip>
  );
});
export const DeleteButtonComponentWithToolTip = memo((props) => {
  return (
    <Tooltip
      title={
        <span className="tooltipTitle">
          {props?.tooltipTitle.split("_").join("  ")}
        </span>
      }
      arrow
    >
      <Button
        startIcon={
          props?.btnIcon ? (
            <props.btnIcon
              style={{ width: "25px", height: "25px", ...props?.iconStyle }}
            />
          ) : (
            ""
          )
        }
        className="deleteButton btn-border btn-text"
        style={{ ...props?.style }}
        onClick={props?.btnClick}
      >
        {props?.name?.toUpperCase()}
      </Button>
    </Tooltip>
  );
});
