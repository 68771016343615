// VariableRadiusPieChart.js
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

const TradeTypeVariableRadiusPieChart = ({ data }) => {
  const options = {
    chart: {
      type: "packedbubble",
    },
    title: {
      text: "Requests by Type",
    },
    plotOptions: {
      packedbubble: {
        minSize: "20%",
        maxSize: "100%",
        useHTML: true,
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.value}",
          style: {
            color: "white",
            fontWeight: "bold",
            fontSize: "16px",
          },
        },
      },
    },
    series: [
      {
        name: "Requests",
        data: data?.map((item) => ({
          name: item?._id,
          value: item?.requests,
          z: item?.requests,
        })),
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default TradeTypeVariableRadiusPieChart;
