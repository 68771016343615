import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeBlockBlack = ({ block }) => {
  return (
    <div
      style={{
        backgroundColor: "#3E444F",
        border: "1px solid #3E444F",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <SyntaxHighlighter
        language={
          block?.language === "C#" ? "cs" : block?.language?.toLowerCase()
        }
        style={vscDarkPlus}
        customStyle={{
          backgroundColor: "transparent",
          fontSize: "14px",
          fontFamily: "'Fira Code', 'Consolas', monospace",
          padding: "15px",
          lineHeight: "1.5",
          margin: 0,
        }}
        showLineNumbers={true}
      >
        {block?.code}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeBlockBlack;
