import React, { useState } from "react";

const CustomTooltip = ({ title, children, top, left, arrowPosition }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            position: "absolute",
            top: top ? top : "-100%",
            left: left ? left : "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 93, 145, 0.8)",
            color: "#ffffff",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: "12px",
            whiteSpace: "nowrap",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 9999,
            textAlign: "center",
          }}
        >
          {title}
          <span
            style={{
              position: "absolute",
              bottom: "-6px",
              left: arrowPosition ? arrowPosition : "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              borderLeft: "6px solid transparent",
              borderRight: "6px solid transparent",
              borderTop: "6px solid rgba(0, 93, 145, 0.8)",
            }}
          ></span>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
