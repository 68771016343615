// import "./Scss/login.scss";
import React, { useEffect, useState } from "react";
import {
  getPasswordResetDetails,
  getPasswordResetForSales,
  tradeApiPasswordActivation,
} from "../../Services/service";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import LoginPassword from "../../Component/LoginPasswordComponent/loginPassword";
import Error from "../../Component/ErrorLoader/error";
import Loading from "../../Component/SuccessLoader/success";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  password: Yup.string()
    .required("This value is required.")
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Z]).{8,16}$/,
      "Please enter strong password"
    ),
  reTypePassword: Yup.string()
    .required("This value is required.")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
});
const PasswordReset = (props) => {
  const navigate = useNavigate();
  const [pathName, setPathName] = useState("");

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});

  const search = useLocation().search;
  const path = useLocation().pathname;

  useEffect(() => {
    setPathName(path);
  }, [path]);

  const userId = new URLSearchParams(search).get("id");
  const userid = new URLSearchParams(search).get("userid");
  const adminUserId = new URLSearchParams(search).get("adminuserid");
  const staySubUser = new URLSearchParams(search).get("staysubuser");

  function resetPassword(values, isSales, isTradeApiUser) {
    setloading(true);
    let resetDetails = {};
    resetDetails.passwordId = userId;
    resetDetails.password = values.password;

    const resetPasswordApi = isTradeApiUser
      ? tradeApiPasswordActivation
      : isSales
      ? getPasswordResetForSales
      : getPasswordResetDetails;

    resetPasswordApi(resetDetails)
      .then((resetPasswordResponse) => {
        if (resetPasswordResponse.status === 200) {
          setTimeout(() => {
            navigate(`/password/verify`, {
              state: {
                userId: userId,
                userid: userid,
                adminUserId: adminUserId,
                staySubUser: staySubUser,
                isFromSales: isSales,
                isFromTradeApiUser: isTradeApiUser,
              },
            });
            setloading(false);
          }, 1000);
        } else {
          setloading(false);
          seterror(true);
          seterrorMsg({
            title: resetPasswordResponse.data.type,
            content: resetPasswordResponse.data.msg,
            open: true,
          });
        }
      })
      .catch((e) => {});
  }

  return (
    <div>
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      {loading && (
        <Loading title={"Please Wait..."} content={"Reseting your password"} />
      )}
      <Formik
        initialValues={{
          reTypePassword: "",
          password: "",
        }}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={(values) => {
          resetPassword(
            values,
            path === "/sales/password/reset-link",
            path === "/api/user/password/reset-link"
          );
        }}
      >
        {({ errors, touched, values }) => (
          <LoginPassword
            login="false"
            text="Enter your password and confirm password to reset the password."
            subject1="Password"
            type1="password"
            name1="password"
            subject1Placeholder="Password"
            touched1={touched.password}
            errors1={errors.password}
            subject2="Confirm Password"
            type2="password"
            name2="reTypePassword"
            subject2Placeholder=" Confirm Password"
            touched2={touched.reTypePassword}
            errors2={errors.reTypePassword}
            buttonText="Submit"
            isShowing={true}
            values={values}
            isFromLogin={false}
          />
        )}
      </Formik>
    </div>
  );
};
export default PasswordReset;
