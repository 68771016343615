import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const LogarithmicChart = ({ data }) => {
  const dates = data?.map((item) => item?.date);
  const requests = data?.map((item) => item?.requests);

  const options = {
    chart: {
      type: "line",
    },
    title: {
      text: "Requests Over Dates",
    },
    xAxis: {
      categories: dates,
      title: {
        text: "Date",
      },
    },
    yAxis: {
      type: "logarithmic",
      title: {
        text: "Requests",
      },
    },
    series: [
      {
        name: "Requests",
        data: requests,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
