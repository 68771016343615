export const userHeader = [
  { title: "First Name", key: "first_name" },
  { title: "Last Name", key: "last_name" },
  { title: "Email", key: "email_id" },
  { title: "Role", key: "role" },
  { title: "Available Credits", key: "available_credits" },
  { title: "Mobile No	", key: "mobile_no" },
  { title: "Added On", key: "created_ts" },
  { title: "Status", key: "is_active" },
  { key: "EditBtn", title: "Action" },
];

export const UserRole = {
  ADMINISTRATOR: "ADMINISTRATOR",
};

export const Modal_header = {
  Edit_User_Details: "Edit User Details",
  Add_User_Details: "Add User Details",
};
