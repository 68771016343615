import React, { useCallback, useEffect, useState } from "react";
import CopyCellRenderer from "./CopyCellRenderer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomDataTable from "./CustomDataTable";
import { IconButton } from "@mui/material";
import { tableEnum } from "../Table.enum";
import moment from "moment";
import {
  DATEFORMAT,
  GETDATAFROMURL,
} from "../../../Containers/ExploreCountryShipmentNewUI/Explore.enum";
import { useLocation } from "react-router-dom";

const GridHandler = ({
  columns,
  rows,
  showPagination,
  handleCollapseOpenModal,
  getSelectedRow,
  handleSelectedShipments,
  descendingAndAscending,
  handleDescendingAndAscendingOrder,
  isWorkSpace,
}) => {
  const search = useLocation().search;
  const currentCountry = new URLSearchParams(search).get(
    GETDATAFROMURL?.country
  );
  const [rowData, setRowData] = useState([]);
  //   const [selectedData, setSelectedData] = useState([]);
  const [sortColumns, setSortColumns] = useState([]);

  const onGridReady = useCallback(() => {
    setRowData(rows);
  }, [rows]);

  const getSortFieldsName = () => {
    const colName =
      descendingAndAscending &&
      Object.keys(descendingAndAscending).map((item) => item);
    setSortColumns(colName);
  };

  useEffect(() => {
    onGridReady();
    getSortFieldsName();
  }, [onGridReady, descendingAndAscending]);

  const filterFields = [
    "isFavourite",
    "isFavouriteShipment",
    "_id",
    "add",
    "checkbox",
  ];

  const renameFields = [
    "PRODUCT_DESCRIPTION",
    "PRODUCT_DESCRIPTION_NATIVE",
    "PRODUCT_DESCRIPTION_EN",
  ];

  const pinnedColumns = [
    "action1",
    "action2",
    "HS_CODE",
    "HS_DESCRIPTION",
    "PRODUCT_DESC_EN",
    "CMDT_CODE",
    "CUSTOMS_DESCRIPTION",
    currentCountry !== "INDIA" && "HS_CODE_DESCRIPTION",
    ...renameFields,
  ];

  const fullWidthColumns = [
    "PRODUCT_DESCRIPTION",
    "PRODUCT_DESCRIPTION_EN",
    "PRODUCT_DESC_EN",
    "CUSTOMS_DESCRIPTION",
  ];
  const customWidthColumns = [
    "IMPORTER_NAME",
    "SUPPLIER_NAME",
    "SHIPPER_NAME_EN",
    "CONSIGNEE_NAME_EN",
  ];

  const tableHeaders = [
    {
      field: "actions",
      filter: false,
      headerName: "",
      pinned: "left",
      width: "65px",
      sortable: false,
      menuTabs: [],
      lockPinned: true,
      lockPosition: "left",
      suppressNavigable: true,
      suppressMovable: true,
    },
    {
      field: "checkField",
      filter: false,
      headerName: "",
      pinned: "left",
      width: "60px",
      sortable: false,
      lockPinned: true,
      lockPosition: "left",
      suppressNavigable: true,
      suppressMovable: true,
      // menuTabs: [],
    },
    columns
      ?.filter((item) => !filterFields.includes(item.key))
      ?.map((item) => {
        const value = {};
        if (pinnedColumns.includes(item.key)) {
          value.pinned = "left";
          value.lockPinned = true;
          value.lockPosition = "left";
        }
        if (sortColumns.includes(item.key)) {
          value.sortable = true;
        } else {
          value.sortable = false;
        }
        if (fullWidthColumns.includes(item.key)) {
          value.width = "300px";
        }
        if (customWidthColumns.includes(item.key)) {
          value.width = "300px";
        }
        if (item.key === "HS_CODE_DESCRIPTION") {
          value.width = "170px";
        }
        if (item.key === "HS_CODE") {
          value.width = "120px";
        }

        // value.menuTabs = ["filterMenuTab", "generalMenuTab", "columnsMenuTab"];
        value.suppressMovable = true;
        value.menuTabs = [];
        value.unSortIcon = true;
        value.field = item.key;
        value.height = "50px";
        value.headerName = renameFields.includes(item.key)
          ? "GOODS DESCRIPTION"
          : item.title.replace(/_/g, " ");
        return value;
      }),
  ].flat();

  const ActionsCellRenderer = (params) => {
    if (params.column.colId === "actions") {
      return (
        <>
          {params?.data && (
            <IconButton
              size="small"
              onClick={() => {
                handleCollapseOpenModal(
                  columns,
                  params.data,
                  params.node.childIndex
                );
              }}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          )}
        </>
      );
    }
  };

  const getColHeaders = (header, ActionsCellRenderer) => {
    return header.map((item) => {
      // Set initial sort order based on the descendingAndAscending mapping
      if (
        descendingAndAscending[item.field] &&
        descendingAndAscending[item.field] !== "default"
      ) {
        item.sort = descendingAndAscending[item.field];
      }

      if (item.field === "actions") {
        item.cellRenderer = ActionsCellRenderer;
      }

      if (item.field === "checkField") {
        item.checkboxSelection = (params) => {
          return !!params?.data && params?.data?.status !== "blocked";
        };
      }

      if (tableEnum?.COPY_ENABLE_FIELDS?.includes(item.field)) {
        item.cellRendererSelector = (params) => {
          return {
            component: CopyCellRenderer,
          };
        };
      }

      if (tableEnum?.formateDateColumns?.includes(item.field)) {
        item.cellRenderer = (params) => {
          return moment(params.data?.[item?.field]).format(DATEFORMAT?.format);
        };
      }

      return item;
    });
  };

  return (
    <>
      <CustomDataTable
        data={rowData}
        header={getColHeaders(tableHeaders, ActionsCellRenderer)}
        pagination={showPagination}
        countPer={true}
        // setSelectedData={setSelectedData}
        // getContextMenuItems={getContextMenuItems}
        // getMainMenuItems={getMainMenuItems}
        handleSelectedShipments={handleSelectedShipments}
        handleDescendingAndAscendingOrder={handleDescendingAndAscendingOrder}
        descendingAndAscending={descendingAndAscending}
        isWorkSpace={isWorkSpace}
      />
    </>
  );
};

export default GridHandler;
