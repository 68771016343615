import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const BasicColumnChart = ({ data }) => {
  const dates = data?.map((item) => item?.date);
  const creditsUtilized = data?.map((item) => item?.creditsUtilized);

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Credits Utilized Over Dates",
    },
    xAxis: {
      categories: dates,
      title: {
        text: "Date",
      },
    },
    yAxis: {
      title: {
        text: "Credits Utilized",
      },
    },
    series: [
      {
        name: "Credits Utilized",
        data: creditsUtilized,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
