import React, { useEffect, useState } from "react";
import "./TradeUserSideBar.scss";
import { IconButton } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import KeyIcon from "@mui/icons-material/Key";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleIcon from "@mui/icons-material/Article";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SsidChartIcon from "@mui/icons-material/SsidChart";

const tabs = [
  { name: "Dashboard", icon: <EqualizerIcon />, to: "/trade/dashboard" },
  { name: "API Key", icon: <KeyIcon />, to: "/trade/api-keys" },
  {
    name: "API Documentation",
    icon: <ArticleIcon />,
    to: "/trade/api-documentation",
  },
  { name: "Api Logs", icon: <SsidChartIcon />, to: "/trade/api-logs" },
  { name: "Support", icon: <SupportAgentIcon />, to: "/ticket-manager" },
];

const TradeUserSideBar = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleSidebarToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (window?.screen?.width < 769) {
      setIsCollapsed(true);
    }
  }, [window?.screen?.width]);

  return (
    <div className="trade-dashboard-container">
      <div className={`trade-sidebar ${isCollapsed ? "trade-collapsed" : ""}`}>
        <ul className="trade-tab-list">
          {tabs.map((tab, index) => (
            <Link
              key={index}
              className={`trade-tab-item ${
                currentPath === tab?.to ? "active-trade" : ""
              }`}
              to={tab?.to}
            >
              {tab.icon}
              {!isCollapsed && (
                <span className="trade-tab-text">{tab.name}</span>
              )}
            </Link>
          ))}
        </ul>

        <div className="trade-close-profile-tab">
          <ul className="trade-tab-list ">
            <Link
              className={`trade-tab-item profile ${
                currentPath === "/trade/user/profile" ? "active-trade" : ""
              }`}
              to="/trade/user/profile"
            >
              <AccountCircleIcon />
              {!isCollapsed && <span className="trade-tab-text">Profile</span>}
            </Link>
          </ul>
          <div>
            <IconButton
              className="trade-toggle-btn"
              onClick={handleSidebarToggle}
            >
              {isCollapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
              {isCollapsed ? "" : "Close"}
            </IconButton>
          </div>
        </div>
      </div>
      <div className="trade-sidebar-table-container">{children}</div>
    </div>
  );
};

export default TradeUserSideBar;
