// ColumnChart.js
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CountryStatsColumnChart = ({ data }) => {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Credits Utilized by Country",
    },
    xAxis: {
      categories: data?.map((item) => item._id),
      title: {
        text: "Country",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Credits Utilized",
      },
    },
    series: [
      {
        name: "Credits Utilized",
        data: data?.map((item) => item.creditsUtilized),
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default CountryStatsColumnChart;
