import React from "react";
import ReadmeRenderer from "../Documentations/ReadmeRenderer";

const ApiDocumentation = () => {
  return (
    <>
      <ReadmeRenderer />
    </>
  );
};

export default ApiDocumentation;
