import "./forgotPassword.scss";
import React, { useState } from "react";
import EximpediaLogo from "../../Asset/images/logo-dark-og.png";
import {
  getForgotPasswordDetails,
  getForgotPasswordDetailsForTradeUser,
} from "../../Services/service";
import { useNavigate } from "react-router-dom";
import AuthFooter from "../../Component/AuthFooter/authFooter";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import { useLocation } from "react-router-dom";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("This value is required."),
});
const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const isTradeUser = queryParams.get("isTradeUser") === "true";

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  function forgotPassword(values) {
    setloading(true);
    let forgotPasswordDetails = {
      userEmail: values.email,
    };

    const forgotPasswordApi = isTradeUser
      ? getForgotPasswordDetailsForTradeUser
      : getForgotPasswordDetails;
    forgotPasswordApi(forgotPasswordDetails)
      .then((forgotPasswordResponse) => {
        setTimeout(() => {
          if (
            forgotPasswordResponse?.data === 1 ||
            forgotPasswordResponse?.data?.desc === "User Not Found"
          ) {
            navigate("/password/recovery/submitted");
          } else {
            seterror(true);
            setloading(false);
            seterrorMsg({
              title: forgotPasswordResponse?.data?.msg || "Warning",
              content:
                forgotPasswordResponse?.data?.desc || forgotPasswordResponse,
              open: true,
            });
            setloading(false);
          }
        }, 500);
        seterror(false);
      })
      .catch((e) => {
        setloading(false);
      });
  }
  return (
    <div>
      {loading && (
        <Loading title={"Email"} content={"Sending email for password reset"} />
      )}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={(values) => {
          forgotPassword(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            {/* <button type="submit">Submit</button> */}
            <div className="background-content mb-5" id="forgot-password">
              <div className="container">
                <div className="rowCards">
                  <div
                    style={{ marginTop: "50px" }}
                    className="card-forgot bg-pattern"
                  >
                    <div className="card-body">
                      <div className="text-center w-75 m-auto">
                        <a href="/consumers/login" rel="noopener noreferrer">
                          <span>
                            <img
                              className="img-center"
                              src={EximpediaLogo}
                              alt=""
                              height="44"
                            />
                          </span>
                        </a>
                        <p className="text-muted mb-4 mt-3">
                          Enter your email address and password to access admin
                          panel.
                        </p>
                      </div>
                      {/* <form id="form-account-login" className="parsley-components"> */}
                      <div className="form-group">
                        <label>Email Id</label>
                        <Field
                          type="email"
                          id="email-id-input"
                          name="email"
                          className="form-control-forgot"
                          placeholder="Enter a valid e-mail"
                        />
                        {touched.email && errors.email && (
                          <div className="error-message">{errors.email}</div>
                        )}
                      </div>
                      <div className="form-group mb-0 text-center">
                        <button
                          type="submit"
                          id="cta-login-account"
                          style={{ marginTop: "20px" }}
                          className="set-submit-btn button-color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AuthFooter />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ForgotPassword;
