import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { logger } from "../../../../Logger/Logger";
import ProfileCards from "./ProfileCards";
import ErrorPopup from "../../../../Component/ErrorLoader/ErrorPopup";

const ProfilePageWithError = (props) => {
  function ErrorHandler({ error }) {
    logger(`Trade User Profile ${error?.message}`);
    return (
      <div role="alert">
        {error && <ErrorPopup errorMsg={error.message} open={error} />}
      </div>
    );
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <ProfileCards />
      </ErrorBoundary>
    </>
  );
};

export default ProfilePageWithError;
