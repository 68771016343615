import React, { useEffect, useState } from "react";
import "./ApiKeyPage.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ButtonComponent } from "../../../../Component/ButtonComponent/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  clientApiCredentials,
  regenerateClientApiCredentials,
} from "../../../../Services/service";
import toast, { Toaster } from "react-hot-toast";
import Error from "../../../../Component/ErrorLoader/error";
import { logger } from "../../../../Logger/Logger";

const ApiKeyPage = () => {
  const [errorMsg, seterrorMsg] = useState({});
  const [error, setError] = useState(false);
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [clientApikeys, setClientApikeys] = useState({});

  const togglePrivateKeyVisibility = () => {
    setShowPrivateKey((prev) => !prev);
  };

  const copyToClipboard = (text, msg) => {
    navigator.clipboard.writeText(text);
    toast.success(msg);
  };

  const errorBlock = (errStatus) => {
    const errorForLogger = `TradeApi key page:- ${errStatus}`;
    logger(errorForLogger);
    setError(true);
    seterrorMsg({
      title:
        errStatus === 400 || errStatus === 401
          ? "Warning"
          : (errStatus === 500 || errStatus === 502) && "Error",
      content:
        errStatus === 400
          ? "Failed to get Profile Detail"
          : errStatus === 401
          ? "Session TimeOut , Please Login Again"
          : "Something Went Wrong , Please Login Again",
    });
  };

  const fetchClientCredentials = () => {
    clientApiCredentials()
      .then((resKey) => {
        if (resKey?.data && resKey?.status === 200) {
          setClientApikeys({
            client_id: resKey?.data?.client_id,
            client_secret: resKey?.data?.client_secret,
          });
        }

        if (
          resKey?.status === 400 ||
          resKey?.status === 401 ||
          resKey?.status === 500
        ) {
          errorBlock(resKey?.status);
        }
      })
      .catch((err) => {
        errorBlock(err);
      });
  };

  const handleRegenerateCall = () => {
    setShowPrivateKey(false);
    regenerateClientApiCredentials()
      .then((res) => {
        if (res?.data && res?.status === 200) {
          setClientApikeys({
            client_id: res?.data?.client_id,
            client_secret: res?.data?.client_secret,
          });
          toast.success("Token regenerated successfully!");
        }
        if (res?.status === 400 || res?.status === 401 || res?.status === 500) {
          errorBlock(res?.status, res?.data?.data?.type);
        }
      })
      .catch((err) => {
        toast.error("Failed to regenerate token.");
      });
  };

  useEffect(() => {
    fetchClientCredentials();
  }, []);

  return (
    <div className="api-key-page">
      {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
      <div className="api-card">
        <div className="card-header">
          <h1>API Keys</h1>
          <div className="regenerate-btn">
            <ButtonComponent
              name={"Regenerate Token"}
              btnIcon={RefreshIcon}
              style={{ margin: "0px 0px 0px 8px " }}
              btnClick={handleRegenerateCall}
            />
          </div>
        </div>
        <div className="key-container">
          <div className="key-row">
            <label>Client Id :</label>
            <div className="private-key-input">
              <span className="access-key">{clientApikeys?.client_id}</span>
              <button
                onClick={() =>
                  copyToClipboard(clientApikeys?.client_id, "Client Id Copied!")
                }
                className="action-btn"
              >
                <ContentCopyIcon />
              </button>
            </div>
          </div>
          <div className="key-row">
            <label>Client Secret Key :</label>
            <div className="private-key-input">
              <span className="private-key">
                {showPrivateKey
                  ? clientApikeys?.client_secret
                  : "****************************************************************"}
              </span>
              <div className="btn-container">
                <button
                  onClick={togglePrivateKeyVisibility}
                  className="toggle-visibility-btn"
                >
                  {showPrivateKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </button>
                <button
                  onClick={() =>
                    copyToClipboard(
                      clientApikeys?.client_secret,
                      "Secret Key Copied!"
                    )
                  }
                  className="action-btn"
                >
                  <ContentCopyIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyPage;
