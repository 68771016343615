import LogOut from "../Containers/Logout/logout";
import SignUp from "../Containers/SignUp/SignUp";
import ErrorPage from "../Containers/ErrorPage/errorPage";
import ForgotPassword from "../Containers/ForgotPassword/forgotPassword";
import PasswordRecovery from "../Containers/PasswordRecoverySubmit/passwordRecovery";
import PasswordReset from "../Containers/PasswordResetLink/passwordrReset";
import EmailActivation from "../Containers/EmailActivation/emailActivation";
import EmailVerification from "../Containers/EmailVerification/emailVerification";
import ExploreWithError from "../Containers/ExploreCountryShipmentNewUI/index";
import AnalyzeWorkspace from "../Containers/Workspace/Component/AnalyzeWorkSpace/AnalyzeWorkSpace";
import VerifyPassword from "../Containers/VeirfyPassword/verify";
import GlobalSearchWithError from "../Containers/GlobalSearchNewUI";
// import DashboardWithError from "../Containers/Dashboard";
import DashboardWithError from "../Containers/DashboardNewUI/index";
import ShipmentWithError from "../Containers/FavouriteShipment";
import ManageUserWithError from "../Containers/User";
import LoginWithError from "../Containers/Login";
import FavCompanyWithError from "../Containers/FavouriteCompany";
import CompanyWithError from "../Containers/MarketPlace/MarketCompany";
import ProductWithError from "../Containers/MarketPlace/MarketProduct";
import MarketCountryWithError from "../Containers/MarketPlace/MarketCountry";
import RequestedBuyerWithError from "../Containers/RequestBuyerAndData/RequestedBuyers";
import UserAccountWithError from "../Containers/Account";
import ActivityTrackerWithError from "../Containers/ActivityTracker";
import SubscriptionWithError from "../Containers/Subscriptions";
import TicketManagerWithError from "../Containers/TicketRaise/index";
import ExploreCountryWithError from "../Containers/ExploreCountryNewUI";
import SummaryDetailsForTradeWithError from "../Containers/SummaryDetailsForTrade";
import WorkspaceWithError from "../Containers/Workspace";
import TrainingWithError from "../Containers/Training/index";
import MirrorDataWithError from "../Containers/MirrorData";
import AnalyzeWorkSpaceWithError from "../Containers/Workspace/Component/AnalyzeWorkSpace/index";
import SummaryDetailsForTradePowerBiWithError from "../Containers/SummaryDetailsForTradePowerBi/index";
import CompanyDetailWithError from "../Containers/CompanyDetails";
import RequestedDataWithError from "../Containers/RequestBuyerAndData/RequestedData";
import ProfileCards from "../Containers/TradeAPIContainer/components/ProfilePage/index";
import ApiKeyPage from "../Containers/TradeAPIContainer/components/ApiKeyPage/ApiKeyPage";
import ApiDocumentation from "../Containers/TradeAPIContainer/components/ApiDocumentation/ApiDocumentation";
import TradePanelDashboard from "../Containers/TradeAPIContainer/components/TradePanelDashboard/TradePanelDashboard";
import RecentTradeApiLog from "../Containers/TradeAPIContainer/components/RecentTradeApiLog/RecentTradeApiLog";

export const pageRoutes = [
  {
    path: "/consumers/logout",
    exact: true,
    element: LogOut,
  },
  {
    path: "/countries/shipments/explorer",
    exact: true,
    element: ExploreWithError,
  },
  {
    path: "/consumers/dashboard",
    exact: true,
    element: DashboardWithError,
  },
  {
    path: "/countries",
    exact: true,
    element: ExploreCountryWithError,
  },
  {
    path: "/trade/summary",
    exact: true,
    element: SummaryDetailsForTradeWithError,
  },
  {
    path: "/trade/summary/powerBi",
    exact: true,
    element: SummaryDetailsForTradePowerBiWithError,
  },
  {
    path: "/workspaces",
    exact: true,
    element: WorkspaceWithError,
  },
  {
    path: "/workspace/shipments/analysis",
    exact: true,
    element: AnalyzeWorkSpaceWithError,
  },
  {
    path: "/users",
    exact: true,
    element: ManageUserWithError,
  },
  {
    path: "/favorite/company",
    exact: true,
    element: FavCompanyWithError,
  },
  {
    path: "/favorite/shipment",
    exact: true,
    element: ShipmentWithError,
  },
  {
    path: "/requested/buyers",
    exact: true,
    element: RequestedBuyerWithError,
  },
  {
    path: "/accounts/profile",
    exact: true,
    element: UserAccountWithError,
  },
  {
    path: "/subscriptions",
    exact: true,
    element: SubscriptionWithError,
  },
  {
    path: "/ticket-manager",
    exact: true,
    element: TicketManagerWithError,
  },
  {
    path: "/consumers/activity-tracker",
    exact: true,
    element: ActivityTrackerWithError,
  },
  {
    path: "/",
    exact: true,
    element: DashboardWithError,
  },
  {
    path: "*",
    exact: true,
    element: ErrorPage,
  },
  {
    path: "/market/country",
    exact: true,
    element: MarketCountryWithError,
  },
  {
    path: "/market/company",
    exact: true,
    element: CompanyWithError,
  },
  {
    path: "/market/product",
    exact: true,
    element: ProductWithError,
  },
  {
    path: "/globalsearch",
    exact: true,
    element: GlobalSearchWithError,
  },
  {
    path: "/help",
    exact: true,
    element: TrainingWithError,
  },
  {
    path: "/consumers/mirrorData",
    exact: true,
    element: MirrorDataWithError,
  },
  {
    path: "/consumers/companyDetails",
    exact: true,
    element: CompanyDetailWithError,
  },
  {
    path: "/requested/companyData",
    exact: true,
    element: RequestedDataWithError,
  },
];

export const tradePageRoutes = [
  {
    path: "/",
    exact: true,
    element: TradePanelDashboard,
  },
  {
    path: "/trade/dashboard",
    exact: true,
    element: TradePanelDashboard,
  },
  {
    path: "/consumers/logout",
    exact: true,
    element: LogOut,
  },
  {
    path: "/ticket-manager",
    exact: true,
    element: TicketManagerWithError,
  },
  {
    path: "*",
    exact: true,
    element: ErrorPage,
  },
  {
    path: "/trade/user/profile",
    exact: true,
    element: ProfileCards,
  },
  {
    path: "/trade/api-keys",
    exact: true,
    element: ApiKeyPage,
  },
  {
    path: "/trade/api-documentation",
    exact: true,
    element: ApiDocumentation,
  },
  {
    path: "/trade/api-logs",
    exact: true,
    element: RecentTradeApiLog,
  },
];

export const authRoutes = [
  {
    path: "/trade-user/login",
    exact: true,
    element: LoginWithError,
  },
  {
    path: "/login",
    exact: true,
    element: LoginWithError,
  },
  {
    path: "/consumers/login",
    exact: true,
    element: LoginWithError,
  },
  {
    path: "/accounts/registrations",
    exact: true,
    element: SignUp,
  },
  {
    path: "/password/recovery",
    exact: true,
    element: ForgotPassword,
  },
  {
    path: "/password/recovery/submitted",
    exact: true,
    element: PasswordRecovery,
  },
  {
    path: "/api/user/password/reset-link",
    exact: true,
    element: PasswordReset,
  },
  {
    path: "/password/reset-link",
    exact: true,
    element: PasswordReset,
  },
  {
    path: "/sales/password/reset-link",
    exact: true,
    element: PasswordReset,
  },
  {
    path: "/password/verify",
    exact: true,
    element: VerifyPassword,
  },
  {
    path: "/accounts/email/activation",
    exact: true,
    element: EmailActivation,
  },
  {
    path: "/accounts/email/verification",
    exact: true,
    element: EmailVerification,
  },
  {
    path: "*",
    exact: true,
    element: ErrorPage,
  },
];
export const demoAccountPlanRoutes = [
  {
    path: "/workspaces",
    exact: true,
    element: WorkspaceWithError,
  },
  {
    path: "/workspace/shipments/analysis",
    exact: true,
    element: AnalyzeWorkspace,
  },
  {
    path: "/",
    exact: true,
    element: LoginWithError,
  },
  {
    path: "/consumers/dashboard",
    exact: true,
    element: DashboardWithError,
  },
  {
    path: "/countries/shipments/explorer",
    exact: true,
    element: ExploreWithError,
  },
  {
    path: "/consumers/logout",
    exact: true,
    element: LogOut,
  },
  {
    path: "/consumers/activity-tracker",
    exact: true,
    element: ActivityTrackerWithError,
  },
  {
    path: "/accounts/profile",
    exact: true,
    element: UserAccountWithError,
  },
  {
    path: "/subscriptions",
    exact: true,
    element: SubscriptionWithError,
  },
  {
    path: "/ticket-manager",
    exact: true,
    element: TicketManagerWithError,
  },
  {
    path: "/trade/summary/powerBi",
    exact: true,
    element: SummaryDetailsForTradePowerBiWithError,
  },
  {
    path: "/trade/summary",
    exact: true,
    element: SummaryDetailsForTradeWithError,
  },
  {
    path: "/requested/companyData",
    exact: true,
    element: RequestedDataWithError,
  },
];
export const expiredPlanRoutes = [
  {
    path: "/consumers/dashboard",
    exact: true,
    element: DashboardWithError,
  },
  {
    path: "/consumers/logout",
    exact: true,
    element: LogOut,
  },
  {
    path: "/login",
    exact: true,
    element: LoginWithError,
  },
  {
    path: "/",
    exact: true,
    element: LoginWithError,
  },
];
