import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./CsVToTable.scss";
import { InputBase } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "40px",
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#495057",
    border: "1.5px solid #495057",
    textOverflow: "ellipsis",
    borderRadius: "5px",
    [theme.breakpoints.up("sm")]: {
      height: "20px",
    },
  },
}));

const CustomTableWithPagination = () => {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [tradeType, setTradeType] = useState("IMPORT");
  const [tradeFilteredData, setTradeFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Fetch and parse the CSV file
    const fetchCsvData = async () => {
      const fileUrl = "/csv/equivalents.csv"; // Path to your CSV file in the public folder
      const response = await fetch(fileUrl);
      const csvText = await response.text();

      // Parse CSV using PapaParse
      Papa.parse(csvText, {
        header: true,
        dynamicTyping: true,
        complete: (result) => {
          const data = result.data;
          // Set column headers dynamically based on CSV keys
          if (data.length > 0) {
            const columns = Object.keys(data[0]).map((key) => ({
              headerName: key,
              field: key,
            }));
            setColumnDefs(columns);
          }
          setRowData(data);
          const tradeFilteredData =
            data?.length && data?.filter((value) => value.Trade === tradeType);

          setTradeFilteredData(tradeFilteredData);
        },
      });
    };

    fetchCsvData();
  }, []);

  const handleTradeTypeButton = (trade) => {
    const data =
      rowData?.length && rowData?.filter((value) => value.Trade === trade);
    setTradeFilteredData(data);
    setTradeType(trade);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get current rows for the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows =
    tradeFilteredData?.length &&
    tradeFilteredData?.slice(indexOfFirstRow, indexOfLastRow);

  // Render table rows dynamically
  const renderTableRows = () => {
    return (
      currentRows?.length &&
      currentRows?.map((row, index) => (
        <tr key={index}>
          {columnDefs.map((col, colIndex) => (
            <td key={colIndex}>{row[col.field]}</td>
          ))}
        </tr>
      ))
    );
  };

  // Render pagination controls
  const totalPages = Math.ceil(tradeFilteredData?.length / rowsPerPage);
  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={currentPage === number ? "active" : ""}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  const handleSearchCountry = (nameCountry) => {
    setTimeout(() => {
      setSearchTerm(nameCountry);
    }, [300]);
  };

  useEffect(() => {
    if (rowData?.length) {
      const filteredData = rowData.filter((value) =>
        searchTerm.length > 0
          ? value.Country?.toLowerCase().includes(searchTerm.toLowerCase()) &&
            value.Trade === tradeType
          : value.Trade === tradeType
      );
      setTradeFilteredData(filteredData);
      setCurrentPage(1);
    }
  }, [searchTerm, tradeType, rowData]);

  return (
    <div>
      <h2>Countries with Available Parameters</h2>
      <div
        className="tabs"
        style={{
          zIndex: "1",
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {["IMPORT", "EXPORT"].map((tab) => (
            <button
              key={tab}
              className={`tab-button ${tradeType === tab ? "active-tab" : ""}`}
              onClick={() => handleTradeTypeButton(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <StyledInputBase
          className="input-flex"
          id="fast-input"
          onChange={(e) => handleSearchCountry(e.target.value)}
          placeholder="Search Country"
          inputProps={{ "aria-label": "search" }}
          sx={{ justifySelf: "flex-end" }}
        />
      </div>

      <table className="custom-table">
        <thead>
          <tr>
            {columnDefs?.map((col, index) => (
              <th key={index}>{col.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>
      {tradeFilteredData?.length > 0 && renderPagination()}
    </div>
  );
};

export default CustomTableWithPagination;
